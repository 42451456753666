import { useState } from 'react';
// import { Container } from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
// import PageHeader from '../../components/PageHeader';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';
import RecuperarSenha from '../../assets/images/img_recuperar_senha.png'
import GoBackHeader from "../../components/GoBackHeader";
// import NoPendingItems from '../../assets/images/img_success.png';
import CheckEmail from '../../assets/images/check-email.png';

export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const isFormValid = (email);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).ResetPassword({
      Email: email,
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      setServiceError(response.Error.Message);
      setIsResetPaswordSuccess(true);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('email');
    }

    setIsLoading(false);

  }

  return (
    <>
      <GoBackHeader
        backAction={"/login"}
        title={"Recuperar senha"}
      />
      <div className='basic-container'>
        {(!isResetPaswordSuccess) ? (
          <>
            <div className='image-container'>
              <img src={RecuperarSenha} alt='logo' />
            </div>
            <div className='regular-title'>
              Insira seu e-mail cadastrado
            </div>
            <div className='regular-text'>
              Enviaremos um link para o e-mail para que você consiga recuperar a sua senha.
            </div>

            <div style={{ height: '27px' }}></div>
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup
                error={getErrorMessageByFieldName('email')}
              >
                <div className='form-label'>E-mail</div>
                <Input
                  type="text"
                  value={email}
                  placeholder="Insira seu e-mail"
                  onChange={handleChangeEmail}
                  error={getErrorMessageByFieldName('email')}
                />
              </FormGroup>

              <Loader isLoading={isLoading} />

              <ButtonContainer>
                <Button
                  type="button"
                  disabled={!isFormValid}
                  onClick={(e) => handleSubmit(e)}

                >
                  Recuperar senha
                </Button>
              </ButtonContainer>
            </Form >
          </>
        ) : (
          <>
            <div className='image-container'>
              <img src={CheckEmail} alt='logo' />
              {/* <img src={NoPendingItems} alt='logo' /> */}
            </div>

            <div className='regular-title'>
              Enviamos para seu e-mail um link para recuperação da sua senha!
            </div>

            <div style={{ height: '27px' }}></div>

            {(serviceError) ? (
              <ButtonContainer>
                <Button
                  type="button"
                  onClick={(e) => setIsResetPaswordSuccess(false)}
                >
                  Alterar o e-mail e tentar novamente
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Button
                  type="button"
                  onClick={(e) => handleGoToLogin(e)}
                >
                  Ok, entendi
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </div>
    </>
  )
};