import styled from "styled-components";

export const Form = styled.form`
  /* div{
    margin-bottom: 8px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.gray[900]};
  } */
`;

export const ButtonContainer = styled.div`
  /* margin-top: 24px;
  text-align: center;

  button{
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
  } */
`;