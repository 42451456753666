// src/components/MonthYearPicker/MonthYearPicker.jsx

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// import './MonthYearPicker.css';
import {
    format,
    parse,
    // isValid,
    isSameMonth,
    isSameYear,
    startOfMonth,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

const MonthYearPicker = ({ selectedDate, onDateChange, setMonthSelectionMode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const pickerRef = useRef(null);
    const selectedOptionRef = useRef(null); // Referência para a opção selecionada

    let formattedDate = '';

    if (selectedDate && selectedDate.includes('T')) {
        const date = new Date(selectedDate);
        formattedDate = format(date, 'yyyy-MM-dd');
    }
    else {
        formattedDate = selectedDate;
    }

    const dateToUse = selectedDate
        ? parse(formattedDate, 'yyyy-MM-dd', new Date())
        : new Date();
    // const dateToUse = selectedDate? selectedDate : ;

    const parsedDate = dateToUse;

    // Fechar o dropdown ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Gerar uma lista de anos: 5 anos no passado e 5 no futuro
    const currentYear = parsedDate.getFullYear();
    const years = [];
    for (let i = currentYear - 15; i <= currentYear + 15; i++) {
        years.push(i);
    }

    // Lista de meses
    const months = [
        'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
        'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];

    // Formatar o mês com a primeira letra maiúscula
    const formattedMonth = format(parsedDate, 'MMMM', { locale: ptBR });
    const formattedYear = format(parsedDate, 'yyyy');

    const handleOptionClick = (monthIndex, year) => {
        setMonthSelectionMode(true);
        const newDate = startOfMonth(new Date(year, monthIndex, 1));
        const formattedDate = format(newDate, 'yyyy-MM-dd');
        onDateChange(formattedDate);
        setIsOpen(false);
    };

    // Efeito para rolar para a opção selecionada quando o dropdown abrir
    useEffect(() => {
        if (isOpen && selectedOptionRef.current) {
            selectedOptionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [isOpen]);

    return (
        <div className="month-year-picker" ref={pickerRef}>
            <div
                className="picker-display"
                onClick={() => setIsOpen(prev => !prev)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') setIsOpen(prev => !prev); }}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
            >
                <span className="month">
                    {formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)}
                </span>
                <span className="year">{formattedYear}</span>
                <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
            </div>
            {isOpen && (
                <div className="picker-dropdown" role="listbox">
                    {years.map(year => (
                        <div key={year} className="year-section">
                            <div className="year-label">{year}</div>
                            <div className="months-grid">
                                {months.map((month, index) => {
                                    const optionDate = new Date(year, index, 1);
                                    const isSelected = isSameMonth(parsedDate, optionDate) && isSameYear(parsedDate, optionDate);
                                    return (
                                        <div
                                            key={month}
                                            ref={isSelected ? selectedOptionRef : null} // Atribuir a referência à opção selecionada
                                            className={`month-option ${isSelected ? 'selected' : ''}`}
                                            onClick={() => handleOptionClick(index, year)}
                                            role="option"
                                            aria-selected={isSelected}
                                            tabIndex={0}
                                            onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleOptionClick(index, year); }}
                                        >
                                            {month.charAt(0).toUpperCase() + month.slice(1)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

MonthYearPicker.propTypes = {
    selectedDate: PropTypes.string.isRequired, // Alterado para string
    onDateChange: PropTypes.func.isRequired,
};

export default MonthYearPicker;
