import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
} from './styles';
// import Button from '../Button';
// import Input from '../Input';
import CloseModalButton from '../../assets/icons/close-modal.svg'

export default function GenericModal({ title, body, onCancel, isVisible }) {
  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div
          className='modal-container'
        >
          <div className='modal-title'>
            <div className='text danger'>{title}</div>
            <div className='close-button'>
              <button
                onClick={onCancel}
              >
                <img src={CloseModalButton} alt='telembro' />
              </button>
            </div>
          </div>
          <div className='modal-body'>
            {body}
          </div>
          
        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

GenericModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isVisible: PropTypes.bool,
}

GenericModal.defaultProps = {
  isVisible: false,
}