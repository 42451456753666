const isAppleDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Check for iPhone
  if (/iPhone/i.test(userAgent)) {
    return "iPhone";
  }
  
  // Check for Mac
  if (/Macintosh/i.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
    return "Mac with Touch Screen (likely an iPad)";
  } else if (/Macintosh/i.test(userAgent)) {
    return "Mac";
  }

  return "Other";
};

export default isAppleDevice;
