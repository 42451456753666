/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Card,
  // ErrorContainer,
  // CardsContainer
} from './styles';
// import Trash from '../../assets/icons/trash.svg';
// import Edit from '../../assets/icons/edit.svg';
// import Attachment from '../../assets/icons/attach-file.svg';
// import Share from '../../assets/icons/share.svg';
import CalendarImage from '../../assets/icons/event.svg';
import SearchIcon from '../../assets/icons/search-icon.svg';
import Filter from '../../assets/icons/filter.svg';
import FormGroup from '../FormGroup';
import Button from '../Button';
import {
  Form,
} from "./styles";
import Select from '../Select';
import Input from '../Input';
// import ToggleList from '../../assets/icons/toggle-list.svg';
// import ToggleGrid from '../../assets/icons/toggle-grid.svg';
// import Notifications from '../../assets/icons/notifications.svg';
// import Info from '../../assets/icons/info.svg';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
import AppointmentTypesService from '../../services/AppointmentTypesService';
// import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
// import Button from '../Button';
// import Button from '../Button';
import Modal from '../Modal';
import GenericModal from '../GenericModal';
// import ModalAttachments from '../ModalAttachments';
import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PropTypes from 'prop-types';
// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import CalendarVision from "../../components/CalendarVision";
// import MessageIcon from '../../assets/icons/message-circle.svg';
import BottomMenu from '../BottomMenu';
import ToggleSwitch from '../ToggleSwitch';
import MonthYearPicker from '../MonthYearPicker';
import CalendarToggle from '../../assets/icons/calendar-toggle.svg';
import ListToggle from '../../assets/icons/list-toggle.svg';
import CalendarToggleBlack from '../../assets/icons/calendar-toggle-black.svg';
import ListToggleBlack from '../../assets/icons/list-toggle-black.svg';
import
// DatePicker,
Calendar
  // , { registerLocale }
  from "react-datepicker";
// import ptBR from 'date-fns/locale/pt-BR';
// registerLocale('pt-br', ptBR)

import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização para português do Brasil
moment.locale('pt-br'); // Define a localização para português do Brasil

export default function Appointments({ fetchOnlyActive, calendarVisible, appointmentTypeId, dateSelected, startDate, endDate, showToggler }) {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  // const [isFilterModalOpen] = useState(false);
  // const [isModalAttachmentsOpen, setIsModalAttachmentsOpen] = useState(false);
  // const [attachmentUrls, setAttachmentUrls] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isMonthSelection, setIsMonthSelection] = useState(false);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [calendarDate, setCalendarDate] = useState(dateSelected);
  const [onlyFetchActive, setOnlyFetchActive] = useState(fetchOnlyActive);
  const [isCalendarVisible, setIsCalendarVisible] = useState((calendarVisible && !dateSelected));
  // const [calendarDateDisplaystring, setCalendarDateDisplaystring] = useState('');
  const [homeViewModel, setHomeViewModel] = useState({});

  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState('');
  const [startDateDate, setStartDateDate] = useState('');
  const [endDateDate, setEndDateDate] = useState('');
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  // const [appointmentCategories, setAppointmentCategories] = useState([]);

  // Função para agrupar compromissos por data
  const groupAppointmentsByDate = (appointments) => {
    return appointments.reduce((acc, appointment) => {
      const date = appointment.AppointmentDate; // Formato 'yyyy-MM-dd'
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(appointment);
      return acc;
    }, {});
  };

  // function filterAppointmentTypes(appointmentTypes, appointmentCategoryId) {
  //   const filteredAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType.AppointmentCategoryId === appointmentCategoryId);

  //   return filteredAppointmentTypes ?? [];
  // }

  // Utility function to get the last date of the month
  // const getLastDateOfMonth = (dateString) => {
  //   // Step 1: Parse the input date string
  //   const [year, month, day] = dateString.split('-').map(Number);

  //   // Note: In JavaScript, months are 0-indexed (0 = January, 11 = December)
  //   const date = new Date(year, month - 1, day);

  //   // Step 2: Calculate the last day of the month
  //   // Set date to first day of the next month, then subtract one day
  //   const lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  //   // Step 3: Format the last date to 'yyyy-MM-dd'
  //   const lastYear = lastDayDate.getFullYear();
  //   const lastMonth = String(lastDayDate.getMonth() + 1).padStart(2, '0');
  //   const lastDay = String(lastDayDate.getDate()).padStart(2, '0');

  //   return `${lastYear}-${lastMonth}-${lastDay}`;
  // };

  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return;
    }

    try {
      setIsLoading(true);

      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAllFiltered(
        {
          UserId: sessionObject.userId,
          FetchOnlyActive: isCalendarVisible ? false : onlyFetchActive,
          // AppointmentTypeId: appointmentTypeId,
          // StartDate: startDate ? startDate : calendarDate,
          // EndDate: endDate ? endDate : (isCalendarVisible ? getLastDateOfMonth(calendarDate) : calendarDate),
          // SelectedDate: isCalendarVisible ? calendarDate : homeViewModel.SelectedDateString,
          SelectedDate: isMonthSelection ? null : calendarDate,
          // IsFilter: false,
        }
      );

      setAppointments(appointmentsList.Result);
      // setCalendarDateDisplaystring(onlyFetchActive ? homeViewModel.TodayPresentationString : homeViewModel.SelectedDatePresentationString);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, isCalendarVisible, onlyFetchActive, calendarDate, isMonthSelection]);

  const filterAppointments = useCallback(async () => {
    if (!sessionObject) {
      return;
    }

    try {
      setIsLoading(true);
      debugger;
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAllFiltered(
        {
          UserId: sessionObject.userId,
          FetchOnlyActive: false,
          AppointmentTypeId: selectedAppointmentTypeId,
          // StartDate: startDate ? startDate : calendarDate,
          // EndDate: endDate ? endDate : (isCalendarVisible ? getLastDateOfMonth(calendarDate) : calendarDate),
          StartDate: startDateDate ? startDateDate : calendarDate,
          EndDate: endDateDate ? endDateDate : calendarDate,
          SelectedDate: null,
          // SelectedDate: isCalendarVisible ? calendarDate : homeViewModel.SelectedDateString,
          // SelectedDate: isCalendarVisible ? calendarDate : null,
          // IsFilter: true,
        }
      );

      setAppointments(appointmentsList.Result);
      // setCalendarDateDisplaystring(onlyFetchActive ? homeViewModel.TodayPresentationString : homeViewModel.SelectedDatePresentationString);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, selectedAppointmentTypeId, startDateDate, calendarDate, endDateDate]);

  const GroupedAppointments = useMemo(() => {
    // Agrupar compromissos por data usando useMemo para otimizar
    // const groupedAppointments = useMemo(() => {
    if (!appointments || appointments.length === 0) {
      return {};
    }

    const grouped = groupAppointmentsByDate(appointments);
    // Ordenar as datas em ordem crescente
    const sortedDates = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b));
    const sortedGrouped = {};
    sortedDates.forEach(date => {
      sortedGrouped[date] = grouped[date];
    });
    return sortedGrouped;
    // }, [appointments]);
  }, [appointments]);

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    const getHomeViewModel = async () => {
      if (!sessionObject) {
        return;
      }

      if (homeViewModel.SelectedDate) {
        return;
      }

      try {
        setIsLoading(true);

        const homeViewModelResponse = await new AppointmentsService(Config.API_BASE_URL).GetHomeViewModel(
          {
            UserId: sessionObject.userId,
            SelectedDateString: dateSelected,
          }
        );

        setHomeViewModel(homeViewModelResponse.Result);
        setCalendarDate(homeViewModelResponse.Result.SelectedDateString);

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    };

    getHomeViewModel();

    listAppointments();
  }, [dateSelected, homeViewModel, listAppointments, sessionObject]);

  const loadAppointmentTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserId(sessionObject.userId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  // const loadAppointmentCategories = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const appointmentCategoriesList = await new AppointmentCategoriesService(Config.API_BASE_URL).listAppointmentCategories();
  //     setAppointmentCategories(appointmentCategoriesList.Result);
  //   } catch (error) {
  //     console.log('error ', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, []);

  useEffect(() => {
    loadAppointmentTypes();
    // loadAppointmentCategories();
  }, [
    loadAppointmentTypes,
    // loadAppointmentCategories,
  ]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    setIsLoading(true);

    // await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
    //   Id: appointmentToDelete.Id,
    //   OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    // });

    setIsLoading(false);

    await handleReload();
  }

  // async function handleAttachments(e, attachmentUrls) {
  //   if (attachmentUrls.length === 1) {
  //     window.open(attachmentUrls[0].FileUrl, '_blank');
  //   }
  //   else {
  //     setAttachmentUrls(attachmentUrls);
  //     setIsModalAttachmentsOpen(true);
  //   }

  // }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    // await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
    //   Id: appointmentToDelete.Id,
    // });

    await handleReload();
  }

  async function handleShareModal(e) {
    setIsShareModalOpen(false);
    setIsLoading(true);
    // window.open(`/shared/${appointmentToShare.Id}`, '_self');
  }

  // function handleOpenModal(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOpen(true);
  // }

  // function handleOpenShareModal(e, appointment) {
  //   setAppointmentToShare(appointment);
  //   setIsShareModalOpen(true);
  // }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  // function handleOpenModalOptions(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOptionsOpen(true);
  // }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  if (!sessionObject) {
    return null;
  }

  if (!homeViewModel) {
    return null;
  }

  return (
    <>
      {(showToggler) && (
        <>
          <div className='toggle-switch-container'>
            <div className='date-part'>
              {/* {calendarDateDisplaystring} */}
              <MonthYearPicker
                selectedDate={calendarDate}
                onDateChange={setCalendarDate}
                setMonthSelectionMode={setIsMonthSelection}
              />
            </div>
            <ToggleSwitch
              isOn={isCalendarVisible === false}
              handleToggle={() => {
                setIsCalendarVisible(!isCalendarVisible);
                setCalendarDate(homeViewModel.TodayDateString);
                setOnlyFetchActive(isCalendarVisible);
              }}
              iconLeftOn={CalendarToggle}
              iconRightOn={ListToggle}
              iconLeftOff={CalendarToggleBlack}
              iconRightOff={ListToggleBlack}
            />
          </div>
        </>
      )}

      {(isCalendarVisible && calendarDate) && (
        <CalendarVision
          selectedDate={calendarDate}
          setSelectedDate={setCalendarDate}
          // setCurrentYear={setCalendarDateDisplaystring}
          // dateSelected={'2023-10-18'}
          isVisible={isCalendarVisible}
          setMonthSelectionMode={setIsMonthSelection}
        />
      )}

      <div className='basic-container'>
        <Loader isLoading={isLoading} />
        <BottomMenu />

        {/* <ModalAttachments
            title='Anexos do compromisso'
            // body='Tem certeza que deseja compartilhar o compromisso?'
            isVisible={isModalAttachmentsOpen}
            // danger
            attachmentUrls={attachmentUrls}
            onConfirm={(e) => {
              setIsModalAttachmentsOpen(false);
            }}
            onCancel={(e) => {
              setIsModalAttachmentsOpen(false);
            }}
          /> */}

        <Modal
          title='Compartilhar compromisso'
          body='Tem certeza que deseja compartilhar o compromisso?'
          isVisible={isShareModalOpen}
          // danger
          onConfirm={handleShareModal}
          onCancel={handleCloseShareModal}
        />

        <Modal
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso? '
          isVisible={isModalOpen}
          danger
          onConfirm={handleDelete}
          onCancel={handleCloseModal}
        />

        <GenericModal
          title=''
          // body='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
          body={[
            <>

              <div className='regular-title'>
                Filtrar compromissos
              </div>
              <div className='regular-text'
                style={{
                  marginBottom: '16px'
                }}>
                Filtre seus compromissos com os controles abaixo
              </div>

              <Form noValidate>
                {/* <Loader isLoading={isLoading} /> */}
                <div>
                  <FormGroup>
                    <div className='form-label'>Tipo de compromisso</div>
                    <Select
                      value={selectedAppointmentTypeId}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setSelectedAppointmentTypeId(e.target.value);
                      }}
                      placeholder='Selecione um tipo'
                    >
                      <option value="">Todos</option>
                      {(appointmentTypes && appointmentTypes.length > 0) &&
                        (
                          appointmentTypes.map((appointmentType) => (
                            <>
                              <option
                                key={`option-${appointmentType.Id}`}
                                value={appointmentType.Id}>{appointmentType.Name}</option>
                            </>
                          ))
                        )}
                    </Select>
                  </FormGroup>
                </div>

                <div className='regular-title smaller'
                  style={{
                    marginTop: '16px',
                    marginBottom: '16px'
                  }}>
                  Período:
                </div>

                <div
                  style={{
                    marginBottom: '16px'
                  }}>
                  <FormGroup>
                    <div className='form-label'>Data inicial</div>
                    <Calendar
                      onChange={(date) => setStartDateDate(date)}
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={startDateDate}
                      placeholder='__/__/____'
                      placeholderText='00/00/0000'
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <Input
                          type="text"
                          maxLength="10"
                          className="small"
                          placeholder='__/__/____'
                        />
                      }
                    />
                  </FormGroup>
                </div>

                <div
                  style={{
                    marginBottom: '16px'
                  }}>
                  <FormGroup>
                    <div className='form-label'>Data Final</div>
                    <Calendar
                      onChange={(date) => setEndDateDate(date)}
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={endDateDate}
                      placeholder='__/__/____'
                      placeholderText='00/00/0000'
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <Input
                          type="text"
                          maxLength="10"
                          className="small"
                          placeholder='__/__/____'
                        />
                      }
                    />
                  </FormGroup>
                </div>

                <div>
                  <FormGroup className='button-container'>
                    <Button
                      type='button'
                      cancel
                      className='button'
                      onClick={(e) => {
                        // setOnlyFetchActive(false);
                        filterAppointments();
                        setIsFilterModalOpen(false);
                      }}
                    >
                      Filtrar
                    </Button>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className='button-container'>
                    <Button
                      type='button'
                      cancel
                      className='secondary'
                      onClick={(e) => {
                        window.open('/appointments', '_self');
                      }}
                    >
                      Limpar filtros
                    </Button>
                  </FormGroup>
                </div>
              </Form >

            </>
          ]}
          isVisible={isFilterModalOpen}
          // danger
          // onConfirm={handleDelete}
          onCancel={() => {
            setIsFilterModalOpen(false);
          }}
        />

        <ModalOptions
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso?
        Ele possui recorrências!'
          isVisible={isModalOptionsOpen}
          danger
          onCancel={handleCloseModalOptions}
          onOption1={handleDelete}
          onOption2={handleDeleteRecurrenceOnly}
          option1Text='Deletar todos'
          option2Text='Deletar esta ocorrência'
        />

        <div
          className="cards-container"
        >
          {(appointments && appointments.length > 0) ? (
            // appointments.map((appointment) => (
            <>
              {
                Object.entries(GroupedAppointments).map(([date, appointmentsList], index) => (
                  <div key={date} className="appointment-group">
                    <div className="appointmentgroup-container">
                      <div className="appointmentgroup-date">
                        {moment(date).format('DD/MM/yyyy')}
                      </div>
                      {(index === 0) && (
                        <div className="appointmentgroup-options">
                          <div className="appointmentgroup-option"
                            onClick={() => {
                              window.open('/search', '_self');
                            }}
                          >
                            <img src={SearchIcon} alt='option' />

                          </div>
                          <div className="appointmentgroup-option"
                            onClick={() => {
                              setIsFilterModalOpen(true);
                            }}
                          >
                            <img src={Filter} alt='option' />
                          </div>
                        </div>
                      )}
                    </div>
                    {
                      appointmentsList.map((appointment) => (
                        <div className="card"
                          key={`apppointmentid-${appointment.Id}`}
                          onClick={(e) => {
                            window.open(`/shared-messages/${appointment.Id}`, '_self');
                          }}
                        >
                          <div className="top-part">
                            <div className="left">
                              <div className="appointment-date">
                                <img src={CalendarImage} alt='date' />
                                {appointment.AppointmentDate ? format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy') : ''}

                                {appointment.AppointmentTime && (
                                  ` - ${appointment.AppointmentTime}`
                                )}
                              </div>
                            </div>
                            <div className="right">
                              <div className="appointment-type" style={{ background: appointment.TagColorHex }}>
                                {appointment.AppointmentTypeName}
                              </div>
                            </div>
                          </div>
                          <div className="bottom-part">
                            <div className="title">
                              {appointment.Name && (
                                <>{appointment.Name}</>
                              )}
                            </div>

                            <div className="observations">
                              {appointment.Observations && (
                                <>{appointment.Observations}</>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </>
          ) : (
            <>
              <div className="appointment-group">
                <div className="appointmentgroup-container">
                  <div className="appointmentgroup-date">

                  </div>
                  <div className="appointmentgroup-options">
                    <div className="appointmentgroup-option"
                      onClick={() => {
                        window.open('/search', '_self');
                      }}
                    >
                      <img src={SearchIcon} alt='option' />

                    </div>
                    <div className="appointmentgroup-option"
                      onClick={() => {
                        setIsFilterModalOpen(true);
                      }}
                    >
                      <img src={Filter} alt='option' />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div >
    </>
  );
}