// ToggleSwitch.jsx
import React from 'react';

export default function SmallerToggleSwitch({ id, checked, onChange, disabled }) {
  return (
    <div className="iphone-toggle-switch">
      <input
        type="checkbox"
        className="iphone-toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="iphone-toggle-switch-label" htmlFor={id}>
        <span className="iphone-toggle-switch-inner" />
        <span className="iphone-toggle-switch-switch" />
      </label>
    </div>
  );
}
