/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Header,
  // ListContainer,
  Card,
  // ErrorContainer,
  CardsContainer
} from './styles';
// import Trash from '../../assets/icons/trash.svg';
// import Edit from '../../assets/icons/edit.svg';
// import Share from '../../assets/icons/share.svg';
// import ArrowUp from '../../assets/icons/arrow-up.svg';
// import { InputSearchContainer } from '../Header/styles';
import FormGroup from '../FormGroup';
import Loader from '../Loader';
import Button from '../Button';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { format } from 'date-fns';
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
// import Button from '../Button';
import Modal from '../Modal';
// import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PlusSign from '../../assets/icons/plus-sign.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFolder,
//   faCalendarDay,
//   faBell,
//   faInfoCircle,
//   faRecycle,
//   // faDollarSign,
// } from '@fortawesome/free-solid-svg-icons'
// // https://fontawesome.com/v5/cheatsheet
// import PropTypes from 'prop-types';
import GoBackHeader from "../../components/GoBackHeader";

export default function AppointmentTypes({ fetchOnlyActive, appointmentTypeId, startDate, endDate }) {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentCategories, setAppointmentCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  // const [appointmentTypeToDelete, setAppointmentTypeToDelete] = useState(null);
  const [appointmentTypeToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  // const [isOptionsVisible, setOptionsVisible] = useState(false);
  // const [plusSignClass, setPlusSignClass] = useState("");
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppointmentTypes = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserIdOnly(sessionObject.userId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  const listAppointmentCategories = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentCategoriessList = await new AppointmentCategoriesService(Config.API_BASE_URL).listAppointmentCategories();
      setAppointmentCategories(appointmentCategoriessList.Result);
      console.log(appointmentCategories);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionObject.userId]);

  async function handleReload() {
    await listAppointmentCategories();
    await listAppointmentTypes();
  };

  useEffect(() => {
    listAppointmentCategories();
    listAppointmentTypes();
  }, [listAppointmentTypes, listAppointmentCategories]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    // setIsModalOptionsOpen(false);

    var response = await new AppointmentTypesService(Config.API_BASE_URL).Delete({
      Id: appointmentTypeToDelete.Id,
    });

    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    else {
      await handleReload();
    }
  }

  // function handleOpenModal(e, appointmentType) {
  //   setAppointmentTypeToDelete(appointmentType);
  //   setIsModalOpen(true);
  // }

  function handleCloseModal() {
    setIsModalOpen(false);
  }


  return (
    <>
      <GoBackHeader
        backAction={"/"}
        title={"Gerenciar tipos"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <Loader isLoading={isLoading} />

        <Modal
          title='Tem certeza que deseja remover o tipo de compromisso?'
          body='Esta ação não poderá ser desfeita'
          isVisible={isModalOpen}
          danger
          onConfirm={handleDelete}
          onCancel={handleCloseModal}
        />

        {!hasError && (
          <>
            <CardsContainer>
              {appointmentTypes && (
                appointmentTypes.map((appointmentType) => (

                  <Card
                    key={`id-${appointmentType.Id}`}
                    onClick={() => {
                      if (!appointmentType.UserId) {
                        alert('Você não pode alterar este tipo');
                      }
                      else {
                        window.open(`/appointment-types/edit/${appointmentType.Id}`, '_self');
                      }
                    }}
                  >
                    <div className="info">
                      <div className="label">Nome do tipo</div>
                      <div className="span">
                        <strong>
                          {appointmentType.Name}
                        </strong>
                      </div>

                    </div>
                  </Card>
                ))
              )}

            </CardsContainer>
            <div className='regular-text'
              style={{
                height: '100px'
                // marginBottom: '16px'
              }}>
            </div>
            <div className='fixed-bottom-button'>
              <FormGroup className='button-container'>
                <Button
                  type='button'
                  cancel
                  className='button'
                  onClick={(e) => {
                    window.open('/appointment-types/new/4', '_self');
                  }}
                >
                  Criar novo tipo
                </Button>
              </FormGroup>
            </div>
          </>
        )}

      </div>
    </>
  );
}

// AppointmentTypes.propTypes = {
// }

// Appointments.defaultProps = {
//   error: null,
// }