import styled, { css } from "styled-components";

export default styled.button`
  color: #FFF;

  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: background all 0.2s ease-in;
  cursor: pointer;

  display: flex;
  width: 100%;
  max-width: 328px;
  height: 56px;
  min-height: 56px;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--primary, #F25C05);
  border: none;


  /* &:hover{
    background: ${({ theme }) => theme.colors.primary.light};
  }

  &:active{
    background: ${({ theme }) => theme.colors.primary.dark};
  } */

  &[disabled]{
    background: #CCC;
    border: 2px solid #CCC;
    cursor: default;
  }

  /* ${({ theme, danger }) => danger && css`
    background: ${theme.colors.danger.main};

    &:hover{
      background: ${theme.colors.danger.light};

      &[disabled]{
        background: #CCC;
        border: 2px solid #CCC;
        cursor: default;
      }
    }

    &:active{
      background: ${theme.colors.danger.dark};
    }
  `}; */

  /* ${({ theme, secondary }) => secondary && css`
    border-radius: 10px;
    border: 1px solid var(--telembro-laranja, #F25C05);
    background: var(--telembro-laranja-l, #FFF1E9);

    color: var(--telembro-laranja, #F25C05);
    text-align: center;

    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `};

  ${({ theme, cancel }) => cancel && css`
    border-radius: 10px;
    border: 1px solid var(--telembro-laranja, #F25C05);
    background: var(--telembro-laranja-l, #FFFFFF);

    color: #A1A1A1;
    text-align: center;

    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `}; */
`;