/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Card,
  // ErrorContainer,
  // CardsContainer,
  Form,
} from './styles';
import FormGroup from "../../components/FormGroup";
import Select from "../../components/Select";
import Loader from '../Loader';
import BottomMenu from '../BottomMenu';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
// import Button from '../Button';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import Calendar from '../../assets/icons/event.svg';
import AcceptShare from '../../assets/icons/accept-share.svg';
import DenyShare from '../../assets/icons/deny-share.svg';
import NoPendingItems from '../../assets/images/img_success.png';
// import Notifications from '../../assets/icons/notifications.svg';
// import Info from '../../assets/icons/info.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faCheckCircle,
//   faTimesCircle,
// } from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
// import { height } from '@fortawesome/free-solid-svg-icons/fa0';

export default function SharedAppointmentsManagement({ appointmentId }) {
  const [sharedAppointments, setSharedAppointments] = useState([]);
  const [approveSharedAppointments, setApprovedSharedAppointments] = useState([]);
  const [showPending, setshowPending] = useState(true);
  const [approvalTypeToShow, setApprovalTypeToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  // const [hasError] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [activeTab, setActiveTab] = useState("Tab1");

  // Handler to switch tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);

    if (tab === 'Tab1') {
      setApprovalTypeToShow(1);
      setshowPending(true);
      handleReloadPending();
    }
    if (tab === 'Tab2') {
      setApprovalTypeToShow(2);
      setshowPending(false);
      handleReloadApproved();
    }
  };

  // Content for each tab
  // const renderContent = () => {
  //   switch (activeTab) {
  //     case "Tab1":
  //       return <div><h2>Content for Tab 1</h2><p>This is the first tab.</p></div>;
  //     case "Tab2":
  //       return <div><h2>Content for Tab 2</h2><p>This is the second tab.</p></div>;
  //     default:
  //       return null;
  //   }
  // };

  const listAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      if (appointmentId) {
        const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getSinglePendingOfApproval(sessionObject.userId, appointmentId);
        if (appointmentsList.Result) {
          setSharedAppointments([appointmentsList.Result]);
        }
        else {
          setSharedAppointments([]);
        }
      }
      else {
        const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllPendingOfApproval(sessionObject.userId);
        setSharedAppointments(appointmentsList.Result);
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, appointmentId]);

  const listApprovedAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllApproved(sessionObject.userId);
      setApprovedSharedAppointments(appointmentsList.Result);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  async function handleReloadPending() {
    await listAppointments();
  };

  async function handleReloadApproved() {
    await listApprovedAppointments();
  };

  useEffect(() => {
    if (!sessionObject) {
      window.open('/login', '_self');
    }

    listAppointments();
    listApprovedAppointments();
  }, [listAppointments, listApprovedAppointments, sessionObject]);

  async function handleApprove(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: true,
      });
      handleReloadPending();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveApproval(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).removeApproval({
        Id: sharedAppointmentId,
        IsAccepted: false,
      });
      handleReloadApproved();

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRefuse(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: false,
      });
      handleReloadPending();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeApprovalTypeToShow(e) {
    setApprovalTypeToShow(e.target.value);
    if (e.target.value === '1') {
      setshowPending(true);
      handleReloadPending();
    }
    else {
      setshowPending(false);
      handleReloadApproved();
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='tabs-container'>
        {/* Tab Headers */}
        <div className='tabs-header'>
          <button
            className={`tab-button ${(activeTab === "Tab1" ? 'active' : '')}`}
            onClick={() => handleTabClick("Tab1")}
          >
            Pendentes
          </button>
          <button
            className={`tab-button ${(activeTab === "Tab2" ? 'active' : '')}`}
            onClick={() => handleTabClick("Tab2")}
          >
            Aprovados
          </button>
        </div>
      </div>
      <div className='basic-container'>
        {/* <div className="image-header">
          <img src={CalendarImage} alt='telembro' />
        </div> */}
        <BottomMenu />
        <div className="" style={{ display: 'none' }}>
          <Form noValidate>
            <Loader isLoading={isLoading} />
            <div>
              <FormGroup>
                <div className='form-label'>Exibir compromissos</div>
                <Select
                  value={approvalTypeToShow}
                  onChange={handleChangeApprovalTypeToShow}
                  placeholder='Selecione uma opção'
                >
                  <option value="1">Pendentes</option>
                  <option value="2">Aprovados</option>
                </Select>
              </FormGroup>
            </div>
          </Form >
        </div >

        <div className='tabs-container'>
          {/* Tab Content */}
          <div className="tab-content">
            {(showPending) ? (
              <>
                {(!sharedAppointments || sharedAppointments.length === 0) && (
                  <div className="no-pending-content">
                    <div>
                      <img src={NoPendingItems} alt='success' />
                    </div>
                    <div className="no-pending-text">
                      Não há compromissos pendentes de aprovação
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {(!approveSharedAppointments || approveSharedAppointments.length === 0) && (
                  <div className="no-pending-content">
                    <div>
                      <img src={NoPendingItems} alt='success' />
                    </div>
                    <div className="no-pending-text">
                      Não há compromissos aprovados
                    </div>
                  </div>
                )}
              </>
            )}


            <>
              <div
                className="cards-container"
              >
                {(showPending) && (
                  <>
                    {sharedAppointments && (
                      sharedAppointments.map((sharedAppointment) => (
                        <>
                          <div
                            className="pending-text"
                          >
                            Convite para participar do compromisso:
                          </div>
                          <div className="card"
                            key={`apppointmentid-${sharedAppointment.Appointment.Id}`}
                            onClick={(e) => {
                              window.open(`/shared-messages/${sharedAppointment.Appointment.Id}`, '_self');
                            }}
                          >
                            <div className="top-part">
                              <div className="left">
                                <div className="appointment-date">
                                  <img src={Calendar} alt='date' />
                                  {sharedAppointment.Appointment.AppointmentDate ? format(new Date(sharedAppointment.Appointment.AppointmentDate), 'dd/MM/yyyy') : ''}

                                  {sharedAppointment.Appointment.AppointmentTime && (
                                    ` - ${sharedAppointment.Appointment.AppointmentTime}`
                                  )}
                                </div>
                              </div>
                              <div className="right">
                                <div className="appointment-type" style={{ background: sharedAppointment.Appointment.TagColorHex }}>
                                  {sharedAppointment.Appointment.AppointmentTypeName}
                                </div>
                              </div>
                            </div>
                            <div className="bottom-part">
                              <div className="title">
                                {sharedAppointment.Appointment.Name && (
                                  <>{sharedAppointment.Appointment.Name}</>
                                )}
                              </div>

                              <div className="observations">
                                {sharedAppointment.Appointment.Observations && (
                                  <>{sharedAppointment.Appointment.Observations}</>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="actions">
                            <div
                              className="confirm"
                              onClick={(e) => {
                                handleApprove(e, sharedAppointment.Id)
                              }}
                            >
                              <img src={AcceptShare} alt='date' />
                              Aceitar
                            </div>
                            <div
                              className="refuse"
                              onClick={(e) => {
                                handleRefuse(e, sharedAppointment.Id)
                              }}
                            >
                              <img src={DenyShare} alt='date' />
                              Recusar
                            </div>
                          </div>
                        </>

                      ))
                    )}
                  </>
                )}
                {(!showPending) && (
                  <>
                    {approveSharedAppointments && (
                      approveSharedAppointments.map((sharedAppointment) => (
                        <>
                          <div
                            className="pending-text"
                          >
                            {/* Convite para participar do compromisso: */}
                          </div>
                          <div className="card"
                            key={`apppointmentid-${sharedAppointment.Appointment.Id}`}
                            onClick={(e) => {
                              window.open(`/shared-messages/${sharedAppointment.Appointment.Id}`, '_self');
                            }}
                          >
                            <div className="top-part">
                              <div className="left">
                                <div className="appointment-date">
                                  <img src={Calendar} alt='date' />
                                  {sharedAppointment.Appointment.AppointmentDate ? format(new Date(sharedAppointment.Appointment.AppointmentDate), 'dd/MM/yyyy') : ''}

                                  {sharedAppointment.Appointment.AppointmentTime && (
                                    ` - ${sharedAppointment.Appointment.AppointmentTime}`
                                  )}
                                </div>
                              </div>
                              <div className="right">
                                <div className="appointment-type" style={{ background: sharedAppointment.Appointment.TagColorHex }}>
                                  {sharedAppointment.Appointment.AppointmentTypeName}
                                </div>
                              </div>
                            </div>
                            <div className="bottom-part">
                              <div className="title">
                                {sharedAppointment.Appointment.Name && (
                                  <>{sharedAppointment.Appointment.Name}</>
                                )}
                              </div>

                              <div className="observations">
                                {sharedAppointment.Appointment.Observations && (
                                  <>{sharedAppointment.Appointment.Observations}</>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="actions">
                            <div
                              className="confirm"
                            // onClick={(e) => {
                            //   handleApprove(e, sharedAppointment.Appointment.Id)
                            // }}
                            >
                              {/* <img src={AcceptShare} alt='date' />
                              Aceitar */}
                            </div>
                            <div
                              className="refuse"
                              onClick={(e) => {
                                handleRemoveApproval(e, sharedAppointment.Id);
                              }}
                            >
                              <img src={DenyShare} alt='date' />
                              Remover
                            </div>
                          </div>
                        </>
                      ))
                    )}
                  </>
                )}

                {/* approveSharedAppointments */}
              </div>
            </>
          </div>
        </div>
      </div>

      <div style={{ height: '75px' }}></div>
    </ >
  );
}