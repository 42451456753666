import { useState } from 'react';
import {
  Form,
  ButtonContainer,
  // Container
} from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
// import Select from '../Select';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import GoBackHeader from "../../components/GoBackHeader";
import GenericModal from '../GenericModal';
import ArrowDown from '../../assets/icons/arrow-down.svg';

export default function AppointmentTypeForm({
  appointmentType,
  onSubmit,
}) {
  const [name, setName] = useState(appointmentType.Name);
  // const [tagColorHex, setTagColorHex] = useState(appointmentType.TagColorHex);
  const [selectedColor, setSelectedColor] = useState(appointmentType.TagColorHex);
  const [isColorsModalOpen, setIsColorsModalOpen] = useState(false);

  const colors = [
    '#8153E4',
    '#536AE4',
    '#43904F',
    '#904343',
    '#745403',
    '#B092EE',
    '#8998E9',
    '#8AB390',
    '#C08F8F',
    '#BC983F',
    '#CBBAEE',
    '#B3BFFC',
    '#AFC8B3',
    '#D2B7B7',
    '#F9DA8D',
  ];
  const colors2 = [

    '#E469B3',
    '#3FA2C6',
    '#76C905',
    '#E65252',
    '#3C464F',
    '#FFA9DD',
    '#60BBDC',
    '#90C843',
    '#F19898',
    '#4F5C68',
    '#FFCDEB',
    '#8AD8F4',
    '#B2E073',
    '#F2BEBE',
    '#8190A1',
  ];

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (name && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  // const [serviceError, setServiceError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        name,
        selectedColor
      });


    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
      return false;
    }
    // else {
    //   setServiceError(null);
    // }

    // setIsCreateUserSuccess(true);
    // setIsLoading(false);
    handleGoToList();
  }

  async function handleGoToList() {
    window.open('/appointment-types', '_self');
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'o nome é obrigatório' });
    } else {
      removeError('name');
    }
  }

  return (
    <>
      <GoBackHeader
        backAction={"/appointment-types"}
        title={"Novo tipo"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <GenericModal
          title=''
          // body='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
          body={[
            <>

              <div className='regular-title'>
                Selecione uma cor
              </div>
              <div className='colors-container'>
                <div
                  className='colors-wall'
                  key='colors-wall-1'
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  <>
                    {colors.map((color) => (
                      <div
                        key={`color1-${color}`}
                        className='color-item'
                        onClick={() => {
                          setSelectedColor(color);
                          setIsColorsModalOpen(false);
                        }}
                        style={{
                          backgroundColor: color,
                          // width: '50px',
                          // height: '50px',
                          cursor: 'pointer',
                          border: selectedColor === color ? '3px solid black' : 'none',
                        }}
                      />
                    ))}
                  </>
                </div>
                <div
                  className='colors-wall'
                  key='colors-wall-2'
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  <>
                    {colors2.map((color) => (
                      <div
                        key={`color2-${color}`}
                        className='color-item' // Added className
                        onClick={() => {
                          setSelectedColor(color);
                          setIsColorsModalOpen(false);
                        }}
                        style={{
                          backgroundColor: color,
                          // width: '50px',
                          // height: '50px',
                          cursor: 'pointer',
                          border: selectedColor === color ? '3px solid black' : 'none',
                        }}
                      />
                    ))}
                  </>
                </div>
              </div>




            </>
          ]}
          isVisible={isColorsModalOpen}
          // danger
          // onConfirm={handleDelete}
          onCancel={() => {
            setIsColorsModalOpen(false);
          }}
        />

        <Form onSubmit={handleSubmit} noValidate >
          <FormGroup
            error={getErrorMessageByFieldName('name')}
          >
            <div className='form-label'>Nome do tipo</div>
            <Input
              type="text"
              value={name}
              placeholder="Insira o nome do tipo"
              onChange={handleChangeName}
              error={getErrorMessageByFieldName('name')}
            />
          </FormGroup>

          <FormGroup
            onCLick={() => {
              setIsColorsModalOpen(true);
            }}
          >
            <div
              type='button'
              onCLick={() => {
                setIsColorsModalOpen(true);
              }}
              className='form-label'>Cor da tag</div>
            <button
              type='button'
              className='fake-select'
              onClick={() => setIsColorsModalOpen(true)}>
              <img src={ArrowDown} alt='option' />
              <div
                key={selectedColor}
                className='color-item selected'
                onCLick={() => {
                  setIsColorsModalOpen(true);
                }}
                style={{
                  backgroundColor: selectedColor,
                  // width: '50px',
                  // height: '50px',
                  cursor: 'pointer',
                  // border: selectedColor === selectedColor ? '3px solid black' : 'none',
                }}
              />
            </button>
          </FormGroup>

          <Loader isLoading={isLoading} />

          <ButtonContainer>
            <Button
              type="button"
              disabled={!isFormValid}
              onClick={(e) => handleSubmit(e)}

            >
              Salvar
            </Button>
          </ButtonContainer>

        </Form>

      </div>
    </>

  );
}