import { useState } from 'react';
import {
  Form,
  ButtonContainer,
  // Container
} from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
// import TextArea from '../TextArea';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import GoBackHeader from "../../components/GoBackHeader";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function AppVersionForm({
  appVersion,
  onSubmit,
}) {
  const [versionNumber, seVersionNumber] = useState(appVersion.VersionNumber);
  const [versionDetails, setVersionDetails] = useState(appVersion.VersionDetails);
  // const [isCurrentVersion, setIsCurrentVersion] = useState(appVersion.IsCurrentVersion);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (versionNumber && versionDetails && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  // const [serviceError, setServiceError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        versionNumber,
        versionDetails,
      });

    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    // else {
    //   setServiceError(null);
    // }

    // setIsCreateUserSuccess(true);
    // setIsLoading(false);
    handleGoToList();
  }

  async function handleGoToList() {
    window.open('/app-versions', '_self');
  }

  function handleChangeVersionNumber(e) {
    seVersionNumber(e.target.value);

    if (!e.target.value) {
      setError({ field: 'versionNumber', message: 'o nome da versão é obrigatório' });
    } else {
      removeError('versionNumber');
    }
  }

  // function handleChangeVersionDetails(e) {
  //   setVersionDetails(e.target.value);

  //   if (!e.target.value) {
  //     setError({ field: 'versionDetails', message: 'os detalhes são obrigatórios' });
  //   } else {
  //     removeError('versionDetails');
  //   }
  // }

  return (
    <>
      <GoBackHeader
        backAction={"/app-versions"}
        title={"Versões"}
        mustShowInfoIcon={false}
      />

      <div className='basic-container'>
        <Form onSubmit={handleSubmit} noValidate >
          <FormGroup
            error={getErrorMessageByFieldName('versionNumber')}
          >
            <div className='form-label'>Nome da versão</div>
            <Input
              type="text"
              value={versionNumber}
              placeholder="Nome da versão"
              onChange={handleChangeVersionNumber}
              error={getErrorMessageByFieldName('versionNumber')}
            />
          </FormGroup>
          <FormGroup
            error={getErrorMessageByFieldName('versionDetails')}
            style={{
              height: '10px',
            }}
          >
            <div
              className='form-label'
              style={{
                width: '90%',
                background: 'white',
                height: '25px',
                marginTop: '-6px',
                paddingTop: '6px'
              }}
            >Detalhes da versão</div>
          </FormGroup>
          <FormGroup
            error={getErrorMessageByFieldName('versionDetails')}
            className='overflow-div'
            style={{
              height: '300px',
              maxHeight: '300px',
            }}
          >
            {/* <div
              className='form-label'
              style={{
                width: '90%',
                background: 'white',
                height: '25px',
                marginTop: '-6px',
                paddingTop: '6px'
              }}
            >Detalhes da versão</div> */}
            {/* <TextArea
              type="text"
              value={versionDetails}
              placeholder="Detalhes da versão"
              onChange={handleChangeVersionDetails}
              error={getErrorMessageByFieldName('versionDetails')}
              style={{
                height: '300px',
                maxHeight: '300px',
                paddingTop: '50px'
              }}
            /> */}
            <div style={{ height: '300px', overflow: 'auto' }}>
              <CKEditor
                editor={ClassicEditor}
                data={versionDetails}
                // config={{
                //   extraPlugins: [function (editor) {
                //     editor.ui.view.editable.element.classList.add('custom-editor');
                //   }]
                // }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                style={{
                  height: '300px',
                  maxHeight: '300px',
                  paddingTop: '50px'
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log(data);
                  // setBiography(data);
                  // console.log(data);

                  setVersionDetails(data);

                  if (!data) {
                    setError({ field: 'versionDetails', message: 'os detalhes são obrigatórios' });
                  } else {
                    removeError('versionDetails');
                  }
                }}
              />
            </div>
          </FormGroup>

          <Loader isLoading={isLoading} />

          <ButtonContainer>
            <Button
              type="button"
              disabled={!isFormValid}
              onClick={(e) => handleSubmit(e)}
            >
              Salvar
            </Button>
          </ButtonContainer>

        </Form>
      </div>
    </>
  );
}