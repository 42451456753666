// import { localGet, localRemove } from "../../lib/session";
import {
  useState
  // , useEffect
} from "react";
// import Config from "../../config/environments/local";
// import BigLogo from '../../assets/icons/menu/big-logo.svg'
// import NotificationIcon from '../../assets/icons/notification.svg'
import MenuBackArrow from '../../assets/icons/keyboard_arrow_left.svg'
import Modal from '../Modal';
import OrangeInfoButton from '../../assets/icons/menu/info-icon-orange.svg'


export default function GoBackHeader({ backAction, title, mustShowInfoIcon, actionText, onActionExecute, rightAlignContent }) {

  const [tipTitle, setTipTitle] = useState('Informação');
  const [tipBody, setTipBody] = useState('');
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);

  return (
    <>
      <Modal
        title={tipTitle}
        body={tipBody}
        tip
        isVisible={isTipModalOpen}
        onConfirm={() => setIsTipModalOpen(false)}
        onCancel={() => setIsTipModalOpen(false)}
      />
      <div className='goback-header-container'>
        <div className='left-side-container'>
          <div className='backaction-container'
            onClick={(e) => {
              window.open(backAction, '_self');
            }}
          >
            <img src={MenuBackArrow} alt='go-back' />
          </div>

          <div className='title-container'>
            {title}
          </div>
        </div>

        {(mustShowInfoIcon) && (
          <div className='info-container'>

            <img
              onClick={() => {
                setTipTitle('Atenção');
                setTipBody('Ao preencher a “hora do compromisso”, as notificações do TeLembro funcionarão com base no que foi preenchido. Do contrário, a hora base será meia-noite.Fuso horário: TeLembro considerará o que foi cadastrado nas configurações.');
                setIsTipModalOpen(true);
              }}
              src={OrangeInfoButton}
              alt='info'
              style={{
                marginLeft: '5px',
                cursor: 'pointer',
              }}
            />

          </div>
        )}

        {(rightAlignContent) && (
          <>
            {rightAlignContent}
          </>
        )}

        {(actionText) && (
          <div className='info-container'
            onClick={onActionExecute}
          >
            {actionText}
          </div>
        )}


      </div>
    </>
  );
}