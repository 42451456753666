import React, { useState } from "react";
import Input from "../Input";

export default function InputAuto({
  // label,
  placeholder,
  data,
  value,
  disabled,
  onChange
}) {
  const [suggestions, setSugesstions] = useState([]);
  const [isSuggestionsHidden, setIsSuggestionsHidden] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");

  const handler = e => {
    setSugesstions(data.filter(i => i.startsWith(e.target.value)));
  };

  const handleChange = e => {
    const input = e.target.value;

    if (!input) {
      setIsSuggestionsHidden(true);
    }
    else {
      setIsSuggestionsHidden(false);
    }

    console.log(selectedVal);
    onChange(input);
  };

  const selectSuggestion = (e, value) => {
    // setSelectedVal(value);
    e.target.value = value;
    handleChange(e);
    setIsSuggestionsHidden(true);
    setSelectedVal(value);
    onChange(value);
  };

  // const handleKeyDown = evt => {
  //   if (!["Enter", "Tab", ","].includes(evt.key)) {
  //     // evt.preventDefault();

  //     var value = evt.target.value.trim();
  //     evt.target.value = value;

  //     // onChange(evt);
  //     setSelectedVal("");
  //     setIsSuggestionsHidden(true);
  //   }
  // };
  const handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = evt.target.value.trim();
      evt.target.value = value;

      // onChange(evt);
      setSelectedVal("");
      setIsSuggestionsHidden(true);
    }
  };

  return (
    <div className="sugesstion-auto" style={{ width: '100%' }}>
      <div className="form-control-auto">
        <Input
          placeholder={placeholder}
          type="search"
          value={value}
          onChange={handleChange}
          onKeyUp={handler}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>

      <div
        className="suggestions"
        style={{ display: isSuggestionsHidden ? "none" : "block" }}
      >
        {suggestions.map((item, idx) => (
          <div
            key={"" + item + idx}
            onClick={(e) => {
              selectSuggestion(e, item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
