// src/components/ToggleOption/ToggleOption.jsx

import React from 'react';
import PropTypes from 'prop-types';
// import './ToggleOption.css';

const ToggleOption = ({ isOn, handleToggle, iconLeftOn, iconRightOn, iconLeftOff, iconRightOff }) => {
  return (
    <button
      className={`toggle-option ${isOn ? 'toggle-on' : 'toggle-off'}`}
      onClick={handleToggle}
      aria-pressed={isOn}
      aria-label="Toggle Option"
    >
      {/* Ícone da opção esquerda */}
      <img
        src={iconLeftOff}
        alt="Opção A"
        className="toggle-icon toggle-icon-left"
      />

      {/* Círculo do toggle */}
      <div className="toggle-circle">
        <img
          src={isOn ? iconRightOn : iconLeftOn}
          alt={isOn ? 'Opção B' : 'Opção A'}
          className="toggle-circle-icon"
        />
      </div>

      {/* Ícone da opção direita */}
      <img
        src={iconRightOff}
        alt="Opção B"
        className="toggle-icon toggle-icon-right"
      />
    </button>
  );
};

ToggleOption.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  iconLeftOn: PropTypes.string.isRequired,
  iconRightOn: PropTypes.string.isRequired,
  iconLeftOff: PropTypes.string.isRequired,
  iconRightOff: PropTypes.string.isRequired,
};

export default ToggleOption;
