import styled, { css } from "styled-components";

export default styled.select`
  width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  min-height: 56px;
  height: 56px;
  border: 1px solid #CCC;
  border-radius: 8px !important;
  border-radius: 4px 4px 0px 0px;
  outline: none;
  padding: 25px 16px 9px 16px;
  /* appearance: none; */
  color: #636668 !important;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;

  &:disabled{
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #E1E1E1;
  }

  &:focus{
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  ${({ theme, error }) => error && css`
    color: ${theme.colors.danger.main};
    border-color: ${theme.colors.danger.main} !important;

    &:focus{
      color: ${theme.colors.danger.light};
      border-color: ${theme.colors.danger.main} !important;
    }

  `};
`;