/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container
  , ErrorContainer
} from './styles';
import CalendarMark from '../../assets/icons/calendarmark.svg';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import AppointmentsService from '../../services/AppointmentsService';
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
// import Button from '../Button';
import Button from '../Button';
// import Modal from '../Modal';
// import ModalAttachments from '../ModalAttachments';
// import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PropTypes from 'prop-types';
import BasicHeader from '../BasicHeader';
import BottomMenu from '../BottomMenu';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização para português do Brasil

moment.locale('pt-br'); // Define a localização para português do Brasil

export default function NewAppointments({ fetchOnlyActive }) {
  // const [appointments, setAppointments] = useState([]);
  const [homeViewModel, setHomeViewModel] = useState({});
  const [sharedAppointments, setSharedAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationsExists, setNotificationsExists] = useState(false);
  // const [isModalAttachmentsOpen, setIsModalAttachmentsOpen] = useState(false);
  // const [attachmentUrls, setAttachmentUrls] = useState([]);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [calendarDate, setCalendarDate] = useState(dateSelected);
  // const [isCalendarVisible, setIsCalendarVisible] = useState((calendarVisible && !dateSelected));
  // const [calendarDateDisplaystring, setCalendarDateDisplaystring] = useState('');

  const listAppointmentsToApprove = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllPendingOfApproval(sessionObject.userId);
      
      setSharedAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    listAppointmentsToApprove();
  }, [listAppointmentsToApprove]);

  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return null;
    }

    try {
      setIsLoading(true);

      // const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAllFiltered(
      //   {
      //     UserId: sessionObject.userId,
      //     FetchOnlyActive: fetchOnlyActive,
      //     // AppointmentTypeId: appointmentTypeId,
      //     // StartDate: startDate,
      //     // EndDate: endDate,
      //   }
      // );
      // setAppointments(appointmentsList.Result);

      const viewModel = await new AppointmentsService(Config.API_BASE_URL).GetNewHomeViewModel(
        {
          UserId: sessionObject.userId,
        }
      );

      setHomeViewModel(viewModel.Result);

      if (viewModel.Result.Notifications && viewModel.Result.Notifications.length > 0) {
        // setNotificationsExists(true);
        setNotificationsExists(false);
      }
      else {
        setNotificationsExists(false);
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();

    listAppointments();
  }, [listAppointments]);

  // async function handleDelete(e) {
  //   setIsModalOpen(false);
  //   setIsModalOptionsOpen(false);

  //   setIsLoading(true);

  //   await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
  //     Id: appointmentToDelete.Id,
  //     OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,f
  //   });

  //   setIsLoading(false);

  //   await handleReload();
  // }

  // async function handleAttachments(e, attachmentUrls) {
  //   if (attachmentUrls.length === 1) {
  //     window.open(attachmentUrls[0].FileUrl, '_blank');
  //   }
  //   else {
  //     setAttachmentUrls(attachmentUrls);
  //     setIsModalAttachmentsOpen(true);
  //   }

  // }

  // async function handleDeleteRecurrenceOnly(e) {
  //   setIsModalOpen(false);
  //   setIsModalOptionsOpen(false);

  //   await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
  //     Id: appointmentToDelete.Id,
  //   });

  //   await handleReload();
  // }

  // async function handleShareModal(e) {
  //   setIsShareModalOpen(false);
  //   setIsLoading(true);
  //   window.open(`/shared/${appointmentToShare.Id}`, '_self');
  // }

  // function handleOpenModal(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOpen(true);
  // }

  // function handleOpenShareModal(e, appointment) {
  //   setAppointmentToShare(appointment);
  //   setIsShareModalOpen(true);
  // }

  // function handleCloseShareModal(e, appointment) {
  //   setIsShareModalOpen(false);
  // }

  // function handleCloseModal() {
  //   setIsModalOpen(false);
  // }

  // function handleOpenModalOptions(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOptionsOpen(true);
  // }

  // function handleCloseModalOptions() {
  //   setIsModalOptionsOpen(false);
  // }

  return (
    <>
      <BasicHeader
        NotificationsCount={(homeViewModel.NotificationsCount > 0 || sharedAppointments.length > 0) ? (homeViewModel.NotificationsCount + sharedAppointments.length) : (0)}
      />
      <div className='basic-container'>
        <BottomMenu />

        {(homeViewModel && homeViewModel.Days) && (
          <>
            {(notificationsExists) && (
              <div className='invitation-alert-container'>
                <div className='invitation-alert-icon'>
                  <img src={CalendarMark} alt='profile' />
                </div>
                <div className='invitation-alert-text'>
                  <div className='invitation-alert-top'>
                    Você tem <span>{homeViewModel.Notifications.length}</span> convites pendentes
                  </div>
                  <div className='invitation-alert-bottom'>
                    <a href='/'>
                      Ver convites
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div className='regular-text-container not-wrapable'>
              Olá, <span>{`${sessionObject.userName}`}</span>! Seus compromissos de hoje:
            </div>

            <div className='appointment-card-container'
              onClick={() => {
                window.open(`/cherry-pick/${moment(homeViewModel.Days[0].Date).format('YYYY-MM-DD')}`, '_self');
              }}
            >
              <div className='date-text-container'>
                <div className='day-container'>
                  {/* 05 */}
                  {moment(homeViewModel.Days[0].Date).format('DD')}
                </div>
                <div className='month-container'>
                  {/* MAI */}
                  {moment(homeViewModel.Days[0].Date).format('MMM').toUpperCase()}
                </div>
              </div>
              <div className='appointments-list-container'>
                {(homeViewModel.Days[0].Appointments.length === 0) ? (
                  <div className='no-appointments'>
                    Você não tem compromissos hoje!
                    <a href='/new'>
                      Criar compromisso +
                    </a>
                  </div>
                ) : (
                  <>
                    {(homeViewModel.Days[0].Appointments && homeViewModel.Days[0].Appointments.length >= 4) && (
                      <>
                        <div className='appointment-container'>
                          {homeViewModel.Days[0].Appointments[0].AppointmentTypeName}
                        </div>
                        <div className='appointment-container'>
                          {homeViewModel.Days[0].Appointments[1].AppointmentTypeName}
                        </div>
                        <div className='appointment-container'>
                          {homeViewModel.Days[0].Appointments[2].AppointmentTypeName}
                        </div>
                        <div className='appointment-container'>
                          {homeViewModel.Days[0].Appointments[3].AppointmentTypeName}
                        </div>
                      </>
                    )}
                    {(homeViewModel.Days[0].Appointments && homeViewModel.Days[0].Appointments.length < 4) && (
                      <>
                        {
                          homeViewModel.Days[0].Appointments.map((appointment) => (
                            <div className='appointment-container'>
                              {appointment.AppointmentTypeName}
                            </div>
                          ))
                        }
                      </>
                    )}

                    {(homeViewModel.Days[0].Appointments && homeViewModel.Days[0].Appointments.length > 4) && (
                      <div className='appointment-container next'>
                        + {homeViewModel.Days[0].Appointments.length - 4} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[0].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                      </div>
                    )}
                  </>
                )}

                {/* <div className='appointment-container'>
              Reunião
            </div>
            <div className='appointment-container'>
              Reunião
            </div>
            <div className='appointment-container'>
              Reunião
            </div>
            <div className='appointment-container'>
              Reunião
            </div> */}
              </div>
            </div>

            <div className='regular-text-container'>
              Seus próximos compromissos:
            </div>

            <div className='appointment-cards-container'>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[1].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[1].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[1].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[1].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[1].Appointments && homeViewModel.Days[1].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[1].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[1].Appointments && homeViewModel.Days[1].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[1].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[1].Appointments && homeViewModel.Days[1].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[1].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[1].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[2].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[2].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[2].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[2].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[2].Appointments && homeViewModel.Days[2].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[2].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[2].Appointments && homeViewModel.Days[2].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[2].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[2].Appointments && homeViewModel.Days[2].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[2].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[2].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[3].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[3].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[3].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[3].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[3].Appointments && homeViewModel.Days[3].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[3].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[3].Appointments && homeViewModel.Days[3].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[3].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[3].Appointments && homeViewModel.Days[3].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[3].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[3].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[4].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[4].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[4].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[4].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[4].Appointments && homeViewModel.Days[4].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[4].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[4].Appointments && homeViewModel.Days[4].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[4].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[4].Appointments && homeViewModel.Days[4].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[4].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[4].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[5].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[5].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[5].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[5].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[5].Appointments && homeViewModel.Days[5].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[5].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[5].Appointments && homeViewModel.Days[5].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[5].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[5].Appointments && homeViewModel.Days[5].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[5].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[5].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='appointment-card-container small'
                onClick={() => {
                  window.open(`/cherry-pick/${moment(homeViewModel.Days[6].Date).format('YYYY-MM-DD')}`, '_self');
                }}
              >
                <div className='date-text-container small'>
                  <div className='day-container small'>
                    {moment(homeViewModel.Days[6].Date).format('DD')}
                  </div>
                  <div className='month-container small'>
                    {moment(homeViewModel.Days[6].Date).format('MMM').toUpperCase()}
                  </div>
                </div>
                <div className='appointments-list-container'>
                  {(homeViewModel.Days[6].Appointments.length === 0) ? (
                    <div className='no-appointments'>
                      Sem compromissos
                    </div>
                  ) : (
                    <>
                      {(homeViewModel.Days[6].Appointments && homeViewModel.Days[6].Appointments.length > 1) && (
                        <>
                          <div className='appointment-container'>
                            {homeViewModel.Days[6].Appointments[0].AppointmentTypeName}
                          </div>
                        </>
                      )}
                      {(homeViewModel.Days[6].Appointments && homeViewModel.Days[6].Appointments.length < 2) && (
                        <>
                          {
                            homeViewModel.Days[6].Appointments.map((appointment) => (
                              <div className='appointment-container'>
                                {appointment.AppointmentTypeName}
                              </div>
                            ))
                          }
                        </>
                      )}

                      {(homeViewModel.Days[6].Appointments && homeViewModel.Days[6].Appointments.length > 1) && (
                        <div className='appointment-container next small'>
                          + {homeViewModel.Days[6].Appointments.length - 1} compromissos <a href={`/cherry-pick/${moment(homeViewModel.Days[6].Date).format('YYYY-MM-DD')}`}>Ver mais</a>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

            </div>
          </>
        )}
      </div>

      <Container>
        <Loader isLoading={isLoading} />

        {/* <ModalAttachments
          title='Anexos do compromisso'
          // body='Tem certeza que deseja compartilhar o compromisso?'
          isVisible={isModalAttachmentsOpen}
          // danger
          attachmentUrls={attachmentUrls}
          onConfirm={(e) => {
            setIsModalAttachmentsOpen(false);
          }}
          onCancel={(e) => {
            setIsModalAttachmentsOpen(false);
          }}
        /> */}

        {/* <Modal
          title='Compartilhar compromisso'
          body='Tem certeza que deseja compartilhar o compromisso?'
          isVisible={isShareModalOpen}
          // danger
          onConfirm={handleShareModal}
          onCancel={handleCloseShareModal}
        />

        <Modal
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso? '
          isVisible={isModalOpen}
          danger
          onConfirm={handleDelete}
          onCancel={handleCloseModal}
        />

        <ModalOptions
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso?
        Ele possui recorrências!'
          isVisible={isModalOptionsOpen}
          danger
          onCancel={handleCloseModalOptions}
          onOption1={handleDelete}
          onOption2={handleDeleteRecurrenceOnly}
          option1Text='Deletar todos'
          option2Text='Deletar esta ocorrência'
        /> */}

        {
          hasError && (
            <ErrorContainer>
              <span>
                Ocorreu um erro ao retornar os compromissos
              </span>
              <Button
                type="button"
                secodary
                onClick={(e) => { handleReload(); }}
              >
                Tentar novamente
              </Button>
            </ErrorContainer>
          )
        }
      </Container >
    </>
  );
}