// import { getDate } from "date-fns";
import NewAppointments from "../../components/NewAppointments";
// import CalendarVision from "../../components/CalendarVision";
// import { useParams } from 'react-router-dom';
import { localGet } from "../../lib/session";
import Config from "../../config/environments/local";
import { useState } from "react";

export default function NewHome() {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  if ((!sessionObject) || (!sessionObject.userEmail)) {
    window.open('/login', '_self');
  }

  return (
    <>
      <NewAppointments
        fetchOnlyActive={true}
      />
    </>
  )
};