/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Card,
  // MessageCard,
  // ErrorContainer,
  // CardsContainer,
  // MessageCardsContainer,
  // Form,
} from './styles';
// import FormGroup from "../../components/FormGroup";
// import Select from "../../components/Select";
import GenericModal from '../../components/GenericModal';
import Duplicate from '../../assets/images/duplicate.png'
import DeleteAppointment from '../../assets/images/delete-item.png'
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { format } from 'date-fns';
import Button from '../Button';
import TextArea from '../TextArea';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import Calendar from '../../assets/icons/event.svg';
import ThreeDots from '../../assets/icons/three-dots.svg';
import AddParticipant from '../../assets/icons/add-participant.svg';
import EditItem from '../../assets/icons/edit-item.svg';
import DuplicateItem from '../../assets/icons/duplicate-item.svg';
import BarcodeCopy from '../../assets/icons/barcode-copy.svg';
import DeleteItem from '../../assets/icons/delete-item.svg';
import SendMessageButton from '../../assets/icons/send-message-button.svg';
import SendMessageButtonDisabled from '../../assets/icons/send-message-button-disabled.svg';
// import RecurrenceIcon from '../../assets/icons/event_repeat.svg';
import GoBackHeader from "../../components/GoBackHeader";
// import Notifications from '../../assets/icons/notifications.svg';
// import Info from '../../assets/icons/info.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
// faCheckCircle,
// faTimesCircle,
// } from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentMessagesService from '../../services/SharedAppointmentMessagesService';
import AppointmentsService from '../../services/AppointmentsService';
import { useParams } from 'react-router-dom';

export default function SharedAppointmentMessages() {
  const bottomRef = useRef(null);
  const containerRef = useRef(null);

  const {
    appointmentId,
  } = useParams();

  const [appointmentConversation, setAppointmentConversation] = useState({});
  // const [approveSharedAppointments, setApprovedSharedAppointments] = useState([]);
  // const [showPending, setshowPending] = useState(true);
  // const [approvalTypeToShow, setApprovalTypeToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [expandObservations, setExpandObservations] = useState(false);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteRecurrenceModalOpen, setIsDeleteRecurrenceModalOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [hasError] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [message, setMessage] = useState('');
  const [messageCharCounter, setMessageCharCounter] = useState(0);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  // const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const getConversation = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      if (appointmentId) {
        const appointmentMessagesResponse = await new SharedAppointmentMessagesService(Config.API_BASE_URL).get(appointmentId, sessionObject.userId);
        if (appointmentMessagesResponse.Result) {
          setAppointmentConversation(appointmentMessagesResponse.Result);
        }
        else {
          setAppointmentConversation({});
        }
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, appointmentId]);

  async function handleReload() {
    await getConversation();
  };

  useEffect(() => {
    if (!sessionObject) {
      window.open('/login', '_self');
    }

    getConversation();
  }, [getConversation, sessionObject]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [appointmentConversation]);

  useEffect(() => {
    scrollToBottom();
  }, [appointmentConversation]); // Replace 'messages' with your state variable holding the content


  async function handleDelete(e) {
    setIsDeleteModalOpen(false);
    setIsDeleteRecurrenceModalOpen(false);
    setIsLoading(true);
    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });
    setIsLoading(false);

    window.open('/', '_self');
  }

  async function handleDeleteRecurrenceOnly(e) {

    setIsLoading(true);
    setIsDeleteModalOpen(false);
    setIsDeleteRecurrenceModalOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });



    window.open('/', '_self');
  }

  async function handleSendMessage(e) {
    try {
      if (message.length > 0) {
        setIsLoading(true);
        await new SharedAppointmentMessagesService(Config.API_BASE_URL).send({
          AppointmentId: appointmentId,
          SenderUserId: sessionObject.userId,
          Message: message,
        });
        setMessage('');
        setMessageCharCounter(0);
        handleReload();
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }
  async function handleDuplicate(e) {
    try {
      setIsLoading(true);

      const duplicatedAppointment = await new AppointmentsService(Config.API_BASE_URL).duplicate({
        Id: appointmentId,
      });

      var duplicatedAppointmentid = duplicatedAppointment.Result;
      window.open(`/edit/${duplicatedAppointmentid}`, '_self');
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleCopy = async (textToCopy) => {
    if (!navigator.clipboard) {
      // Fallback for older browsers
      fallbackCopyTextToClipboard(textToCopy);
      return;
    }
    try {
      await navigator.clipboard.writeText(textToCopy);
      // setCopySuccess('Copied!');
    } catch (err) {
      // setCopySuccess('Failed to copy!');
      console.error('Failed to copy: ', err);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        // setCopySuccess('Copied!');
      } else {
        // setCopySuccess('Failed to copy!');
      }
    } catch (err) {
      // setCopySuccess('Failed to copy!');
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  async function handleChangeMessage(e) {

    if (e.target.value.length <= 400) {
      setMessage(e.target.value);
      setMessageCharCounter(e.target.value.length);
    }
  }

  // function getChipClass(appointmentTYpeId) {
  //   switch (appointmentTYpeId) {
  //     case 1:
  //     case 2:
  //     case 3:
  //       return 'habilitacao-identidade-passaporte';
  //     case 4:
  //     case 5:
  //     case 6:
  //       return 'boleto-fatura-carne';
  //     case 7:
  //       return 'reuniao';
  //     case 8:
  //       return 'medico';
  //     default:
  //       return 'criada-usuario';
  //   }
  // }

  return (
    <>

      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={Duplicate} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Duplicar compromisso
            </div>
            <div className='modal-body-text'>
              Deseja criar um novo compromisso com base nos dados deste?
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  handleDuplicate();
                }}
                className="button"
              >
                Duplicar compromisso
              </button>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsDuplicateModalOpen(false);
                }}
                className="secondary"
              >
                Cancelar
              </button>
            </div>
          </>
        ]}
        isVisible={isDuplicateModalOpen}
        onConfirm={handleDuplicate}
        onCancel={(e) => {
          setIsDuplicateModalOpen(false);
        }}
      />

      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={DeleteAppointment} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Excluir compromisso
            </div>
            <div className='modal-body-text'>
              Ao excluir não será possível recuperar os dados do compromisso e o mesmo também será excluído para as pessoas que você compartilhou.
            </div>
            <div className='modal-body-text'>
              Você tem certeza que deseja excluir?
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  // if (appointmentToDelete.OriginalAppointmentId) {
                  //   handleDelete();
                  // }

                  if (!appointmentToDelete.AppointmentHasRecurrences) {
                    handleDelete();
                  }
                  else {
                    setIsDeleteRecurrenceModalOpen(true);
                    setIsDeleteModalOpen(false);
                  }

                }}
                className="button"
              >
                Excluir compromisso
              </button>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsDeleteModalOpen(false);
                }}
                className="secondary"
              >
                Cancelar
              </button>
            </div>
          </>
        ]}
        isVisible={isDeleteModalOpen}
        onConfirm={handleDelete}
        onCancel={(e) => {
          setIsDeleteModalOpen(false);
        }}
      />

      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={DeleteAppointment} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Excluir compromisso recorrente
            </div>
            <div className='modal-body-text'>
              Este compromisso possui recorrrências!
              <br /><br />
              Ao excluir não será possível recuperar os dados do compromisso e o mesmo também será excluído para as pessoas que você compartilhou.
            </div>
            <div className='modal-body-text'>
              Você tem certeza que deseja excluir?
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  handleDeleteRecurrenceOnly();
                }}
                className="button"
              >
                Excluir somente esta ocorrência
              </button>
              
              <button
                type="button"
                onClick={(e) => {
                  handleDelete();
                }}
                className="button"
              >
                Excluir todos
              </button>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsDeleteRecurrenceModalOpen(false);
                }}
                className="secondary"
              >
                Cancelar
              </button>
            </div>
          </>
        ]}
        isVisible={isDeleteRecurrenceModalOpen}
        onConfirm={handleDelete}
        onCancel={(e) => {
          setIsDeleteRecurrenceModalOpen(false);
        }}
      />

      <GoBackHeader
        backAction={"/appointments"}
        title={"Compromisso"}
        mustShowInfoIcon={false}
        rightAlignContent={[
          <div className="right-items-container">


            <div className={`threee-dots-container`}
              onClick={(e) => {
                window.open(`/edit/${appointmentConversation.Appointment.Id}`, '_self');
              }}
            >
              <img src={AddParticipant} alt='enviar'
              />
            </div>

            <div className={`threee-dots-container ${subMenuIsOpen ? 'expanded' : ''}`}
              onClick={(e) => {
                setSubMenuIsOpen(!subMenuIsOpen);
              }}
            >
              <img src={ThreeDots} alt='enviar'
              />
            </div>

            {(subMenuIsOpen) && (
              <div className="sub-menu-container">
                <div className="sub-menu-item-container"
                  onClick={(e) => {
                    window.open(`/edit/${appointmentConversation.Appointment.Id}`, '_self');
                  }}
                >
                  <img src={EditItem} alt='enviar' />
                  Editar
                </div>
                <div className="sub-menu-item-container"
                  onClick={(e) => {
                    setIsDuplicateModalOpen(true);
                    // window.open(`/edit/${appointmentConversation.Appointment.Id}`, '_self');
                  }}
                >
                  <img src={DuplicateItem} alt='enviar' />
                  Duplicar
                </div>
                {(!appointmentConversation.Appointment.SharedAppointmentId) && (
                  <>
                    <div className="sub-menu-item-container"
                      onClick={(e) => {
                        setAppointmentToDelete(appointmentConversation.Appointment);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <img src={DeleteItem} alt='enviar' />
                      Apagar
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        ]}
      />
      <div className="basic-container no-padding">
        <Loader isLoading={isLoading} />

        <div className="fab-container">
          <div className="fab-content">
            <div
              className='text-part'
            >
              <TextArea
                className='message small'
                placeholder='Digite a mensagem aqui'
                value={message}
                onChange={handleChangeMessage}
                onKeyDown={(e) => {
                  e.target.style.height = 'inherit';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              >
              </TextArea>
              <div className='counter'>
                {`${messageCharCounter}/400`}
              </div>
            </div>
            <Button
              type='button'
              className='button'
              disabled={!message}
              onClick={(e) => {
                // window.open("/new", "_self");
                handleSendMessage();
              }}
            >
              {(!message) ?
                (
                  <img src={SendMessageButtonDisabled} alt='enviar' />
                ) : (
                  <img src={SendMessageButton} alt='enviar' />
                )}
            </Button>
          </div>
        </div>

        <div
          className="cards-container single-one"
        >
          {(appointmentConversation && appointmentConversation.Appointment) && (
            <div className="card single"
              key={`apppointmentid-${appointmentConversation.Appointment.Id}`}
            >
              <div className="top-part">
                <div className="left">
                  <div className="appointment-date">
                    <img src={Calendar} alt='date' />
                    {format(new Date(appointmentConversation.Appointment.AppointmentDate), 'dd/MM/yyyy')}

                    {appointmentConversation.Appointment.AppointmentTime && (
                      ` - ${appointmentConversation.Appointment.AppointmentTime}`
                    )}
                  </div>
                </div>
                <div className="right">
                  <div className="appointment-type" style={{ background: appointmentConversation.Appointment.TagColorHex }}>
                    {appointmentConversation.Appointment.AppointmentTypeName}
                  </div>
                </div>
              </div>
              <div className="bottom-part">
                <div className="title">
                  {appointmentConversation.Appointment.Name && (
                    <>{appointmentConversation.Appointment.Name}</>
                  )}
                </div>

                <div className={`observations ${expandObservations ? 'expand' : ''}`}>
                  {appointmentConversation.Appointment.Observations && (
                    <>{appointmentConversation.Appointment.Observations}</>
                  )}
                </div>
                <a
                  className={`see-more-link ${expandObservations ? 'expand' : ''}`}
                  onClick={(e) => {
                    setExpandObservations(!expandObservations)
                  }}
                >{(expandObservations ? 'Ver menos' : 'Ver mais')}</a>

                {(appointmentConversation.Appointment.SharedUserEmails &&
                  appointmentConversation.Appointment.SharedUserEmails.length > 0
                ) && (
                    <>
                      <div className="participants">
                        Participantes:
                        <div className="participants-list">

                          {
                            appointmentConversation.Appointment.SharedUserEmails.map((sharedUserEmail) => (
                              <div className="participant">
                                {sharedUserEmail}
                              </div>
                            ))
                          }

                        </div>
                      </div>
                    </>
                  )}
                {(appointmentConversation.Appointment.BarCodeNumber) && (
                  <div className="participants">
                    Código de Barras:
                    <div className="barcode-number-container">
                      <div className="barcode-number">
                        {appointmentConversation.Appointment.BarCodeNumber}
                      </div>
                      <div className="barcode-copy"
                        onClick={(e) => {
                          handleCopy(appointmentConversation.Appointment.BarCodeNumber);
                          alert("Código de barras copiado com sucesso");
                        }}
                      >
                        <img src={BarcodeCopy} alt='copy' />
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          )}
        </div>
        <div
          className='messages-container'
          ref={containerRef}
        >
          {(appointmentConversation) && (
            <>
              <div
                className='messages-container-title'
              >
                Mensagens do compromisso
              </div>
              {appointmentConversation.SharedAppointmentMessagesDateGrouped && (
                <>
                  {appointmentConversation.SharedAppointmentMessagesDateGrouped.map((sharedAppointmentMessagesDateGrouped) => (
                    <>
                      <div className='messages-date'>
                        {sharedAppointmentMessagesDateGrouped.MessagesDateString}
                      </div>
                      {sharedAppointmentMessagesDateGrouped.SharedAppointmentMessages.map((sharedAppointmentMessage) => (
                        <div
                          key={`shared-appointment-message-${sharedAppointmentMessage.Id}`}
                          className={`message-card-container ${sharedAppointmentMessage.IsSenderMessage ? 'mine' : 'others'}`}
                        >
                          <div className="info">
                            <div className="text">
                              <div className="sender-name">
                                {sharedAppointmentMessage.SenderUserName}
                              </div>
                              <div className="message-text">
                                {sharedAppointmentMessage.Message}
                              </div>
                              <div className="message-time">
                                {sharedAppointmentMessage.MessageHour}
                              </div>
                            </div>

                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </>
              )}
            </>
          )}
          <div ref={bottomRef} className='conversation-bottom-ref' />
        </div>
        <div style={{ height: '75px' }}></div>
      </div>
    </>
  );
}