import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  /* max-height: 111px; */
  
  .input-icon{
    position: absolute;
    right: 11px;
    bottom: 15px;
    z-index: 1;
  }

  &.bordered{
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 16px;
  }

  .counter{
      display: flex;
      justify-content: flex-end;
      color: rgba(170, 170, 170, 0.67);
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  .small-select{
    /* width: 47%; */
  }

  .link-button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;

      img{
        margin-top: -6px;
      }
  }
  
  &.button-group{
    display: flex;
    justify-content: space-between;

    /* div + div {
      margin-right: 10px;
    } */
  }

  &.toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    
    .side-label{
      margin: auto;
      margin-left: 0;

      color: var(--dark, #011F26);
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .creatable-select{
    
    .css-13cymwt-control, .css-t3ipsp-control, .css-16xfy0z-control{
      min-height: 56px;
      padding-top: 10px;
      padding-left: 6px;
      outline: none;
      border: none;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: #FFF;
      color: #636668 !important;
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.36px;
      outline: none;

      &:active{
        border: 1px solid #F15C05;
        box-shadow: none;
      }
      &:focus{
        border: 1px solid #F15C05;
        box-shadow: none;
      }
      &:focus-visible{
        border: 1px solid #F15C05;
        box-shadow: none;
      }

      .css-1u9des2-indicatorSeparator{
        display: none;
      }

      .css-1dimb5e-singleValue{
        color: #636668 !important;
        font-weight: 600;
      }
    }

    .css-1nmdiq5-menu{
      z-index: 2;
    }
  }

  &.small{
    max-width: 50%;
  }

  &.complex {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    max-width: 50%;

    .group {
      input{
        /* max-width: 250px; */
        /* max-width: 90%; */
      }
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.recurrencenit{
        input{
        /* max-width: 250px; */
          max-width: 100px !important;
        }
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-evenly !important;

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;

      &.counter{
        background: ${({ theme }) => theme.colors.primary.main};
        color: #FFF;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        height: 52px;
        border: 2px solid #FFF;
        border-radius: 4px;
        outline: none;
        padding: 0 16px;
        font-size: 16px;
        transition: background all 0.2s ease-in;
        font-weight: bolder !important;
        cursor: pointer;

        /* &:hover{
          background: ${({ theme }) => theme.colors.primary.light};
        }

        &:active{
          background: ${({ theme }) => theme.colors.primary.dark};
        } */
      }
    }
  }

  &.complexcounter {
    .group {
      /* width: 92px; */
      margin: 0;
      margin-right: 6px;
      display: flex;
      align-items: flex-start;
      text-align: left;
      position: relative;
      justify-content: space-between;

      input{
        max-width: 92px;
        text-align: center;
        padding-right: 24px;
        padding-top: 9px;
      }

      /* button{
        position: absolute;
        right: 0;
      } */
      img{
        position: absolute;
        right: 0;
        cursor: pointer;

        &.counter-down{
          bottom: 0;
        }
        &.counter-up{
          top: 0;
        }
      }

      &.recurrenceunit{
        input{
          max-width: 92px !important;
          width: 92px;
        }

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      /* width:24%; */
      width:55px;
      background: transparent;
      color: #FFF;
      height: 52px;
      outline: none;
      /* padding: 0 16px; */
      padding: 12px 21px;
      font-size: 18px;
      transition: background all 0.2s ease-in;
      cursor: pointer;
      padding-left: 14px;
      border: none;

      &:not(.trash-button){
        background: #F6F6F6;
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      }

      color: var(--telembro-azul-d, #A1A1A1);
      text-align: center;
      /* TL-botao */
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 10px;
      margin: auto;
      margin-top: 3px;

      /* &:hover{
        background: ${({ theme }) => theme.colors.primary.light};
      }

      &:active{
        background: ${({ theme }) => theme.colors.primary.dark};
      } */
    }
  }

  small{
    /* margin-top: 8px; */
    font-size: 12px;
    display: block;
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;