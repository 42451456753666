import { localGet, localRemove } from "../../lib/session";
import {
  useState
  , useEffect
} from "react";
import Config from "../../config/environments/local";
// import BigLogo from '../../assets/icons/menu/big-logo.svg'
import HomeOrange from '../../assets/icons/home-orange.svg'
import Home from '../../assets/icons/home.svg'
import Calendar from '../../assets/icons/calendar.svg'
import CalendarOrange from '../../assets/icons/calendar-orange.svg'
import CalendarAdd from '../../assets/icons/calendar-add.svg'
import NewShare from '../../assets/icons/newshare.svg'
import NewShareOrange from '../../assets/icons/newshare-orange.svg'
import Menu from '../../assets/icons/menu.svg'
// import Profile from '../../assets/icons/profile.svg'
import NewSideBar from '../NewSideBar';


export default function BottomMenu({ mustOpenMenu }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));

  useEffect(() => {
    const location = window.location.pathname;
    if (
      (location.includes('appointments')) ||
      (location.includes('cherry-pick'))
    ) {
      setActiveMenu('calendar');
    }
    else if (
      (location.includes('shared-management')) ||
      (location.includes('share'))
    ) {
      setActiveMenu('share');
    }
    else {
      setActiveMenu('home');
    }
  }, []);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleLogout() {
    localRemove(Config.SESSION_KEY);
    setSessionObject(null);
    window.open('/', '_self');
  }

  function handleOpenModalCancelUserRegistration() {
    // setIsCancelUserRegistrationModalOpen(true);
  }

  return (
    <>
      <div className='bottom-menu'>
        <div className='bottom-menu-container'>
          <div className={`bottom-menu-item-container ${(activeMenu === 'home' ? 'active' : '')}`}
            onClick={(e) => {
              window.open('/', '_self');
            }}
          >
            <img src={(activeMenu === 'home' ? HomeOrange : Home)} alt='option' />
            <div className='item-text'>Início</div>
          </div>
          <div className={`bottom-menu-item-container ${(activeMenu === 'calendar' ? 'active' : '')}`}
            onClick={(e) => {
              window.open('/appointments', '_self');
            }}
          >
            <img src={(activeMenu === 'calendar' ? CalendarOrange : Calendar)} alt='option' />
            <div className='item-text'>Calendário</div>
          </div>
          <div className='bottom-menu-item-container  middle'
            onClick={(e) => {
              window.open('/new', '_self');
            }}
          >
            <button>
              <img src={CalendarAdd} alt='option' />
            </button>
          </div>
          <div className={`bottom-menu-item-container ${(activeMenu === 'share' ? 'active' : '')}`}
            onClick={(e) => {
              window.open('/shared-management', '_self');
            }}
          >
            <img src={(activeMenu === 'share' ? NewShareOrange : NewShare)} alt='option' />
            <div className='item-text'>Compart.</div>
          </div>
          <div className='bottom-menu-item-container'
            onClick={(e) => {
              toggleMenu();
            }}
          >
            <img src={Menu} alt='option' />
            <div className='item-text'>Menu</div>
          </div>

        </div>
      </div>

      <NewSideBar
        isVisible={isMenuOpen}
        onClose={toggleMenu}
        onLogOut={handleLogout}
        showUserLink={sessionObject.userEmail === 'telembroapp@gmail.com'}
        onOpenModalCancelUserRegistration={handleOpenModalCancelUserRegistration}
      />
    </>
  );
}