/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Card,
  // ErrorContainer
  // , CardsContainer
} from './styles';
// import Trash from '../../assets/icons/trash.svg';
// import Edit from '../../assets/icons/edit.svg';
// import Attachment from '../../assets/icons/attach-file.svg';
// import Share from '../../assets/icons/share.svg';
import Calendar from '../../assets/icons/event.svg';
import InputSearchIcon from '../../assets/icons/input-search-icon.svg';
import ClearSearchIcon from '../../assets/icons/clear-search-icon.svg';
// import Notifications from '../../assets/icons/notifications.svg';
// import Info from '../../assets/icons/info.svg';
import Loader from '../Loader';
import Input from '../Input';
// import IphoneToggleSwitch from '../IphoneToggleSwitch';

import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import Button from '../Button';
// import Button from '../Button';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PropTypes from 'prop-types';
// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import GoBackHeader from "../../components/GoBackHeader";

export default function SearchAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isToggleChecked, setIsToggleChecked] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appointmentToDelete] = useState(null);
  const [appointmentToShare] = useState(null);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [filter, setFilter] = useState(''); // Estado para o filtro

  const filteredAppointments = useMemo(() => {
    if (!filter) return appointments;

    const lowercasedFilter = filter.toLowerCase();

    return appointments.filter(appointment => {
      const nameMatch = appointment.Name?.toLowerCase().includes(lowercasedFilter);
      const typeMatch = appointment.AppointmentTypeName?.toLowerCase().includes(lowercasedFilter);
      const observationsMatch = appointment.Observations?.toLowerCase().includes(lowercasedFilter);

      return nameMatch || typeMatch || observationsMatch;
    });
  }, [filter, appointments]);

  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAll(sessionObject.userId, false);

      setAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();
  }, [listAppointments]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleShareModal(e) {
    setIsShareModalOpen(false);
    setIsLoading(true);
    window.open(`/shared/${appointmentToShare.Id}`, '_self');
  }

  // function handleOpenModal(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOpen(true);
  // }

  // function handleOpenShareModal(e, appointment) {
  //   setAppointmentToShare(appointment);
  //   setIsShareModalOpen(true);
  // }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  // function handleOpenModalOptions(e, appointment) {
  //   setAppointmentToDelete(appointment);
  //   setIsModalOptionsOpen(true);
  // }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  return (
    <>
      <GoBackHeader
        backAction={"/appointments"}
        title={"Buscar compromisso"}
        mustShowInfoIcon={false}
      />
      <div className="basic-container">
        <Loader isLoading={isLoading} />

        <Modal
          title='Compartilhar compromisso'
          body='Tem certeza que deseja compartilhar o compromisso?'
          isVisible={isShareModalOpen}
          // danger
          onConfirm={handleShareModal}
          onCancel={handleCloseShareModal}
        />

        <Modal
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso? '
          isVisible={isModalOpen}
          danger
          onConfirm={handleDelete}
          onCancel={handleCloseModal}
        />

        <ModalOptions
          title='Deletar compromisso'
          body='Deseja realmente deletar este compromisso?
        Ele possui recorrências!'
          isVisible={isModalOptionsOpen}
          danger
          onCancel={handleCloseModalOptions}
          onOption1={handleDelete}
          onOption2={handleDeleteRecurrenceOnly}
          option1Text='Deletar todos'
          option2Text='Deletar esta ocorrência'
        />

        <div className="search-container">
          <img src={InputSearchIcon} alt='search' />
          <Input
            type="text"
            placeholder=""
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="search-input"
          />
          <img src={ClearSearchIcon} alt='clear' className='clear-filter'
            onClick={(e) => setFilter('')}
          />
          {/* <IphoneToggleSwitch
            id={1}
            checked={isToggleChecked}
            onChange={() => {
              setIsToggleChecked(!isToggleChecked);
            }}
          /> */}
        </div>
        <div className="search-results-count-container">
          {filteredAppointments.length} resultados encontrados
        </div>
        <>
          <div
            className="cards-container"
          >
            {(filteredAppointments && filteredAppointments.length > 0) && (
              filteredAppointments.map((appointment) => (
                <div className="card"
                  key={`apppointmentid-${appointment.Id}`}
                  onClick={(e) => {
                    window.open(`/shared-messages/${appointment.Id}`, '_self');
                  }}
                >
                  <div className="top-part">
                    <div className="left">
                      <div className="appointment-date">
                        <img src={Calendar} alt='date' />
                        {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')}

                        {appointment.AppointmentTime && (
                          ` - ${appointment.AppointmentTime}`
                        )}
                      </div>
                    </div>
                    <div className="right">
                      <div className="appointment-type"
                        style={{
                          background: appointment.TagColorHex
                        }}
                      >
                        {appointment.AppointmentTypeName}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-part">
                    <div className="title">
                      {appointment.Name && (
                        <>{appointment.Name}</>
                      )}
                    </div>

                    <div className="observations">
                      {appointment.Observations && (
                        <>{appointment.Observations}</>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {(!appointments || appointments.length === 0) && (
            <div className='info-page'>
              <div className="image-header">
                {/* <img src={CalendarImage} alt='telembro' /> */}
              </div>
              <div className="text-header">
                0 compromissos encontrados
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
}

// SearchAppointments.propTypes = {
//   fetchOnlyActive: PropTypes.bool.isRequired,
//   appointmentTypeId: PropTypes.number,
//   startDate: PropTypes.string,
//   endDate: PropTypes.string,
// }

// Appointments.defaultProps = {
//   error: null,
// }