import { useState } from 'react';
import { Form, ButtonContainer } from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { localGet, localSet } from '../../lib/session';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pdf from '../../assets/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.pdf'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from "jwt-decode";
// import OneSignal from 'react-onesignal';
// import GoogleIcon from '../../assets/icons/google-icon.svg';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import isEmailValid from '../../utils/isEmailValid';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (email && password);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));

  if (sessionObject) {
    window.open('/', '_self');
  }

  async function handleSuccess(credentialResponse) {
    console.log(credentialResponse); // This will have your tokenId
    // You can pass the tokenId to your backend for further processing
    const tokenId = credentialResponse.credential;

    // Decodificar o JWT para obter as informações do usuário
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded);
    /*
        Estrutura típica do objeto decodificado:
        {
          iss: "https://accounts.google.com",
          nbf: 1592529042,
          aud: "YOUR_CLIENT_ID.apps.googleusercontent.com",
          sub: "110169484474386276334",
          email: "user@example.com",
          email_verified: true,
          name: "John Doe",
          picture: "https://lh3.googleusercontent.com/a-/AOh14Gg...",
          given_name: "John",
          family_name: "Doe",
          locale: "en"
        }
      */

    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).Googlelogin({
      TokenId: tokenId,
      Email: decoded.email,
      Name: decoded.name,
      PictureUrl: decoded.picture
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      // setError({ field: 'password', message: response.Error.Message });
      setIsLoading(false);
    } else {
      removeError('email');
      // removeError('password');
      const sessionObject = {
        userId: response.Result.Id,
        userName: response.Result.Name,
        userEmail: response.Result.Email,
        accessProfileId: response.Result.AccessProfileId,
        pushNotificationSubscriptionId: response.Result.PushNotificationSubscriptionId,
        automaticallyGetUserTimezone: response.Result.AutomaticallyGetUserTimezone,
        timezoneId: response.Result.TimezoneId,
        localTimezoneString: ''
      };

      localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
      localSet(Config.TERMS_KEY, response.Result.HasAcceptedLastTerms, Config.EXPIRATION_AUTH);
      setSessionObject(sessionObject);
    }
  };

  const handleFailure = (error) => {
    console.log('Login Failed', error);
    alert("Não foi possível autenticar o usuário com a conta Google");
  };

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).login({
      Email: email,
      Password: password,
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      // setError({ field: 'password', message: response.Error.Message });
      setIsLoading(false);
    } else {
      removeError('email');
      // removeError('password');
      const sessionObject = {
        userId: response.Result.Id,
        userName: response.Result.Name,
        userEmail: response.Result.Email,
        accessProfileId: response.Result.AccessProfileId,
        pushNotificationSubscriptionId: response.Result.PushNotificationSubscriptionId,
        automaticallyGetUserTimezone: response.Result.AutomaticallyGetUserTimezone,
        timezoneId: response.Result.TimezoneId,
        localTimezoneString: ''
      };

      localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
      localSet(Config.TERMS_KEY, response.Result.HasAcceptedLastTerms, Config.EXPIRATION_AUTH);
      setSessionObject(sessionObject);
    }
    // if (password !== Config.DEFAULT_USER_OBJECT.password) {
    //   setError({ field: 'password', message: 'senha incorreta' });
    //   setIsLoading(false);
    // }
    // else {
    //   removeError('password');
    //   localSet(Config.SESSION_KEY, Config.DEFAULT_USER_OBJECT, Config.EXPIRATION_AUTH);
    //   setSessionObject(Config.DEFAULT_USER_OBJECT);
    // }
  }

  // async function handleGoToRegister(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsLoading(true);

  //   window.open('/users/new', '_self');
  // }

  // async function handleRegister(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setIsLoading(true);

  //   window.open("/users/new", "_self");
  // }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      if(isEmailValid(e.target.value)){
        removeError('email');
      }
      else{
        setError({ field: 'email', message: 'E-mail inválido' });
      }
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'A senha é obrigatória' });
    } else {
      removeError('password');
    }
  }

  return (

    <div className='basic-container'>
      <Form onSubmit={handleSubmit} noValidate>
        {/* <div>E-mail</div> */}
        <FormGroup
          error={getErrorMessageByFieldName('email')}
        >
          <div className='form-label'>Email</div>
          <Input
            // autoFocus
            type="text"
            value={email}
            placeholder="Seu email"
            onChange={handleChangeEmail}
            error={getErrorMessageByFieldName('email')}
          />
        </FormGroup>

        <FormGroup
          error={getErrorMessageByFieldName('password')}
        >
          <div className='form-label'>Senha</div>
          <div className="input-group-btn">
            <Input
              type={passwordType}
              value={password}
              placeholder="Sua senha de acesso"
              onChange={handleChangePassword}
              error={getErrorMessageByFieldName('password')}
            />
            <button type="button" className="btn" onClick={togglePassword}>
              {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
        </FormGroup>

        <Loader isLoading={isLoading} />

        <FormGroup>
          <div className='anchor-container'>
            <a
              href="/forgot-password"
            >
              Esqueci minha senha
            </a>
          </div>
        </FormGroup>

        <ButtonContainer className='button-container'>
          <Button
            type="button"
            disabled={!isFormValid}
            onClick={(e) => handleSubmit(e)}
            className='button'
          >
            Entrar
          </Button>
        </ButtonContainer>

        <FormGroup>
          <div className='anchor-container'>
            Ainda não tem uma conta? <br />
            <a
              href="/users/new"
            >
              Cadastre-se
            </a>
          </div>
        </FormGroup>

        <ButtonContainer className='button-container'>
          {/* <Button
          type="button"
          disabled={!isFormValid}
          onClick={(e) => handleSubmit(e)}
          className='button white'
        >
          <img src={GoogleIcon} alt='google' />
          Entrar com o Google
        </Button> */}
          <GoogleOAuthProvider clientId="1028116951793-gih8nnh6ohcjsa9qqa71b1fded6qokjq.apps.googleusercontent.com">
            <div className='google-login-button'>
              <GoogleLogin
                onSuccess={handleSuccess}
                onError={handleFailure}
              />
            </div>
          </GoogleOAuthProvider>


        </ButtonContainer>

        <div className='small-legal-disclaimer'>
          Ao prosseguir, você concorda com as diretrizes descritas em nossa <br /><a rel='noreferrer' target='_blank' href={Pdf}>Política de privacidade e Termos de uso e navegação</a>
        </div>

      </Form >
    </div>
  );
}
