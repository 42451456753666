import { useParams, useHistory } from 'react-router-dom';
import AppointmentTypeForm from "../../components/AppointmentTypeForm";
import AppointmentTypesService from '../../services/AppointmentTypesService';
import { useState, useEffect } from "react";
import Loader from '../../components/Loader';
import Config from '../../config/environments/local';
// import { localGet } from "../../lib/session";

export default function EditAppointmentType() {

  const [appointmentType, setAppointmentType] = useState({});
  // const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { history } = useHistory();

  useEffect(() => {
    async function getUser() {
      try {
        setIsLoading(true);

        const response = await new AppointmentTypesService(Config.API_BASE_URL).GetById(id);

        setAppointmentType(response.Result);
      } catch (error) {
        console.log('error ', error);
        history.push('/');
      } finally {
        setIsLoading(false);
      }
    }

    getUser();
  }, [id, history]);

  async function handleSubmit(formData) {
    
    const response = await new AppointmentTypesService(Config.API_BASE_URL).Update({
      Id: id,
      Name: formData.name,
      TagColorHex: formData.selectedColor,
      // UserId: sessionObject.userId,
    });

    return response;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <AppointmentTypeForm
        key={appointmentType.Id}
        buttonLabel='Salvar alterações'
        onSubmit={handleSubmit}
        appointmentType={appointmentType}
      />
    </>
  )
};