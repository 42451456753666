/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  // Container,
  // Header,
  Card,
  ErrorContainer,
  CardsContainer
} from './styles';
import Trash from '../../assets/icons/trash.svg';
import Edit from '../../assets/icons/edit.svg';
import GoBackHeader from "../../components/GoBackHeader";
// import Share from '../../assets/icons/share.svg';
// import ArrowUp from '../../assets/icons/arrow-up.svg';
// import { InputSearchContainer } from '../Header/styles';
// import FormGroup from '../FormGroup';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { format } from 'date-fns';
import AppVersionService from '../../services/AppVersionService';
import Button from '../Button';
import Modal from '../Modal';
// import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PlusSign from '../../assets/icons/plus-sign.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faFolder,
//   faCalendarDay,
//   faBell,
//   faInfoCircle,
//   faRecycle,
//   // faDollarSign,
// } from '@fortawesome/free-solid-svg-icons'
// // https://fontawesome.com/v5/cheatsheet
// import PropTypes from 'prop-types';

export default function AppVersion({ fetchOnlyActive, appVersionId, startDate, endDate }) {
  const [appVersions, setAppVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appVersionToDelete, setAppVersionToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  // const [isOptionsVisible, setOptionsVisible] = useState(false);
  // const [plusSignClass, setPlusSignClass] = useState("");
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppVersion = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentCategoriessList = await new AppVersionService(Config.API_BASE_URL).listAppVersion();
      setAppVersions(appointmentCategoriessList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  async function handleReload() {
    await listAppVersion();
    await listAppVersion();
  };

  useEffect(() => {
    listAppVersion();
  }, [listAppVersion]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    // setIsModalOptionsOpen(false);

    var response = await new AppVersionService(Config.API_BASE_URL).delete(appVersionToDelete.Id);

    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    else {
      await handleReload();
    }
  }

  function handleOpenModal(e, appVersion) {
    setAppVersionToDelete(appVersion);
    setIsModalOpen(true);
  }

  async function handleMakeCurrentVersion(e, appVersion) {
    try {
      setIsLoading(true);
      await new AppVersionService(Config.API_BASE_URL).editAppVersion(appVersion.Id, appVersion.VersionNumber, appVersion.VersionDetails, true);
      await handleReload();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }


  return (
    <>
      <Loader isLoading={isLoading} />

      <Modal
        title='Tem certeza que deseja remover a versão?'
        body='Esta ação não poderá ser desfeita'
        isVisible={isModalOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
      />

      <div className="fab-container">
        <div
          className="button-container"
        >
          <Button
            type='button'
            className='button'
            onClick={(e) => { window.open("/app-versions/new", "_self"); }}
          >
            Nova versão
          </Button>
        </div>
      </div>

      <GoBackHeader
        backAction={"/"}
        title={"Versões"}
        mustShowInfoIcon={false}
      />

      <div className='basic-container'>

        {hasError && (
          <ErrorContainer>
            <strong>
              Ocorreu um erro ao retornar os tipos de compromissos
            </strong>
            <Button
              type="button"
              onClick={(e) => { handleReload(); }}
            >
              Tentar novamente
            </Button>
          </ErrorContainer>
        )}

        {!hasError && (
          <>
            <CardsContainer>
              {appVersions && (
                appVersions.map((appVersion) => (
                  <>
                    <Card
                      key={`id-${appVersion.Id}`}
                    >
                      <div className="info">
                        <div className="text">
                          <div className="span type">
                            <b>{appVersion.VersionNumber}</b>
                          </div>

                          <div className="span type version-details-container"
                            dangerouslySetInnerHTML={{ __html: (appVersion.VersionDetails) }}
                          >
                            {/* {appVersion.VersionDetails}{`${appVersion.VersionDetails.length > 100 ? '...' : ''}`}{console.log(appVersion.VersionDetails.length)} */}
                          </div>
                        </div>
                      </div>

                      <div className="actions">
                        {(!appVersion.IsCurrentVersion) && (
                          <button
                            type="button"
                            className='button'
                            onClick={(e) => {
                              handleMakeCurrentVersion(e, appVersion);
                            }}
                          >
                            Tornar vigente
                          </button>
                        )}
                        {(appVersion.IsCurrentVersion) && (
                          <span
                            style={{
                              color: 'green',
                              fontWeight: 'bold'
                            }}
                          >
                            Vigente
                          </span>
                        )}

                        <a href={`/app-versions/edit/${appVersion.Id}`}>
                          <img src={Edit} alt='Edit' />
                        </a>

                        {(!appVersion.IsCurrentVersion) && (
                          <button
                            type="button"
                            onClick={(e) => {
                              handleOpenModal(e, appVersion);
                            }}
                          >
                            <img src={Trash} alt='Trash' />
                          </button>
                        )}
                      </div>
                    </Card>
                    <br />
                  </>
                ))
              )}
            </CardsContainer>
          </>
        )}
      </div>
    </>
  );
}

// AppVersion.propTypes = {
// }

// Appointments.defaultProps = {
//   error: null,
// }