// import {
//   useParams,
//   // useHistory
// } from 'react-router-dom';
// import PageHeader from "../../components/PageHeader";
import AppVersionForm from "../../components/AppVersionForm";
import AppVersionService from '../../services/AppVersionService';
// import { format } from 'date-fns';
import Config from "../../config/environments/local";
// import { localGet } from "../../lib/session";
// import {
//   useState,
// } from 'react';

export default function NewAppVersion() {

  // const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const { appointmentCategoryId } = useParams();
  // const { history } = useHistory();

  // console.log(appointmentCategoryId);
  // console.log(history);

  async function handleSubmit(formData) {
    return await new AppVersionService(Config.API_BASE_URL).createAppVersion(formData.versionNumber, formData.versionDetails, false);
  }

  return (
    <>
      <AppVersionForm
        buttonLabel='Cadastrar'
        onSubmit={handleSubmit}
        appVersion={{
          Id: 0,
          VersionNumber: '',
          VersionDetails: '',
          IsCurrentVersion: false,
        }}
      />
    </>
  )
};