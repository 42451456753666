import HttpClient from './utils/HttpClient';

class TypesService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async GetCities() {
    const urlPath = `/types/cities/get`;

    return this.httpClient.post(urlPath, {});
  }
  async GetStates() {
    const urlPath = `/types/states/get`;

    return this.httpClient.post(urlPath, {});
  }
}

export default TypesService;