import { useState, useEffect } from 'react';
import {
  Form
  , ButtonContainer
  // , Container
} from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Select from '../Select';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TypesService from '../../services/TypesService';
import Config from '../../config/environments/local';
import BigLogo from '../../assets/icons/menu/big-logo.svg'
import CheckEmail from '../../assets/images/check-email.png';
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
// import OrLine from '../../assets/images/new-layout/register_or.svg'
import Pdf from '../../assets/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.pdf'
import InputMask from 'react-input-mask'; // Import the masking library
import GoBackHeader from "../../components/GoBackHeader";
import isEmailValid from '../../utils/isEmailValid';

export default function UserForm({
  onSubmit,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [celular, setCelular] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  // Example of states and cities (you can replace this with an API call)
  // const estados = ['São Paulo', 'Rio de Janeiro', 'Minas Gerais'];
  // const cidadesPorEstado = {
  //   'São Paulo': ['São Paulo', 'Campinas', 'Santos'],
  //   'Rio de Janeiro': ['Rio de Janeiro', 'Niterói', 'Petrópolis'],
  //   'Minas Gerais': ['Belo Horizonte', 'Uberlândia', 'Ouro Preto']
  // };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);


  useEffect(() => {
    async function GetStates() {
      try {
        setIsLoading(true);

        const response = await new TypesService(Config.API_BASE_URL).GetStates();

        setStates(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (states.length === 0) {
      GetStates();
    }
  }, [states]);

  useEffect(() => {
    async function GetCities() {
      try {
        setIsLoading(true);

        const response = await new TypesService(Config.API_BASE_URL).GetCities();

        setCities(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (cities.length === 0) {
      GetCities();
    }
  }, [cities]);

  const handleEstadoChange = (e) => {
    setEstado(e.target.value);
    setCidade(''); // Reset city when state changes
  };

  const handleCelularChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setCelular(value);
  };

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (name && email && password && confirmPassword && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        name,
        email,
        password,
        estado,
        cidade,
        celular
      });


    if (response.Error) {
      setServiceError(response.Error.Message);
    }
    else {
      setServiceError(null);
    }

    setIsCreateUserSuccess(true);
    setIsLoading(false);
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'o nome é obrigatório' });
    } else {
      removeError('name');
    }
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      if(isEmailValid(e.target.value)){
        removeError('email');
      }
      else{
        setError({ field: 'email', message: 'E-mail inválido' });
      }
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    } else {
      if (confirmPassword && (confirmPassword !== e.target.value)) {
        setError({ field: 'password', message: 'digite a mesma senha' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('confirmPassword');
      }
    }
  }

  function handleChangeConfirmePassword(e) {
    setConfirmPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'confirmPassword', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('confirmPassword');
      }
    }
  }

  return (
    (!isCreateUserSuccess) ? (
      <>
        <GoBackHeader
          backAction={"/login"}
          title={"Cadastro"}
        />
        <div className='basic-container'>


          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup
              error={getErrorMessageByFieldName('name')}
            >
              <div className='form-label'>Nome</div>
              <Input
                type="text"
                value={name}
                placeholder="Nome"
                onChange={handleChangeName}
                error={getErrorMessageByFieldName('name')}
              />
            </FormGroup>

            <FormGroup
              error={getErrorMessageByFieldName('email')}
            >
              <div className='form-label'>E-mail</div>
              <Input
                type="text"
                value={email}
                placeholder="E-mail"
                onChange={handleChangeEmail}
                error={getErrorMessageByFieldName('email')}
              />
            </FormGroup>
            <div className='small-disclaimer'>
              Notificações e atualizações serão enviados neste e-mail.
            </div>

            {/* Celular Field with Mask */}
            <FormGroup error={getErrorMessageByFieldName('celular')}>
              <div className='form-label'>Celular <span>(Opcional)</span></div>
              <InputMask
                mask="(99) 99999-9999"
                value={celular}
                onChange={handleCelularChange}
                placeholder="(00) 00000-0000"
              >
                {(inputProps) => <Input {...inputProps} type="tel" />}
              </InputMask>
            </FormGroup>

            <FormGroup
              error={getErrorMessageByFieldName('password')}
            >
              <div className='form-label'>Senha</div>
              <div className="input-group-btn">
                <Input
                  type={passwordType}
                  value={password}
                  placeholder="Senha"
                  onChange={handleChangePassword}
                  error={getErrorMessageByFieldName('password')}
                />
                <button type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
            </FormGroup>
            <div className='small-disclaimer'>
              Mínimo de 8 caracteres <br />
              Possuir letras e números <br />
              Pelo menos 1 legra maiúscula <br />
              Pelo menos 1 caractere especial (#@!)
            </div>
            <FormGroup
              error={getErrorMessageByFieldName('confirmPassword')}
            >
              <div className='form-label'>Confirmar senha</div>
              <div className="input-group-btn">
                <Input
                  type={passwordType}
                  value={confirmPassword}
                  placeholder="Confirmar senha"
                  onChange={handleChangeConfirmePassword}
                  error={getErrorMessageByFieldName('confirmPassword')}
                />
                <button type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
            </FormGroup>

            {/* Estado and Cidade dropdowns */}
            <div style={{ display: 'flex', gap: '16px' }}>
              <FormGroup error={getErrorMessageByFieldName('estado')}>
                <div className='form-label'>Estado <span>(Opcional)</span></div>
                <Select
                  value={estado} onChange={handleEstadoChange}
                  error={getErrorMessageByFieldName('estado')}
                  placeholder='Estado'
                  className="small"
                >
                  <option value="">Selecione</option>
                  {states && (
                    states.map((state) => (
                      <option
                        key={state.Id}
                        value={state.Id}
                      >
                        {state.Name}</option>
                    )))}
                </Select>
              </FormGroup>

              <FormGroup error={getErrorMessageByFieldName('cidade')}>
                <div className='form-label'>Cidade</div>
                <Select
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  disabled={!estado}
                  error={getErrorMessageByFieldName('cidade')}
                  placeholder='Cidade'
                  className="small"
                >
                  <option value="">Selecione</option>
                  {cities && (
                    cities.map((city) => (
                      <option key={city.Id} value={city.Id}>{city.Name}</option>
                    )))}
                </Select>
              </FormGroup>
            </div>

            <Loader isLoading={isLoading} />

            <ButtonContainer>
              <Button
                type="button"
                disabled={!isFormValid}
                onClick={(e) => handleSubmit(e)}

              >
                Cadastrar
              </Button>

            </ButtonContainer>

            <div className='small-legal-disclaimer'>
              {('Ao prosseguir, você concorda com as diretrizes descritas em nossa ')}
              <a rel='noreferrer' target='_blank' href={Pdf}>Política de privacidade</a> {(' e ')}
              <a rel='noreferrer' target='_blank' href={Pdf}>Termos de uso e navegação</a>
            </div>

            {/* <ButtonContainer>
            <img src={OrLine} alt='Ou' />
          </ButtonContainer>

          <ButtonContainer>
            <Button
              type="button"
              secondary
              onClick={(e) => window.open('/login', '_self')}

            >
              Logar no app
            </Button>
          </ButtonContainer> */}

          </Form>
        </div>
      </>
    ) : (
      <>
        <div className="big-logo-container smaller">
          <img src={BigLogo} alt='logo' />
        </div>
        <div className='basic-container'>
          {(serviceError) ? (
            <>
              {serviceError}
            </>
          ) : (
            <>
              <div className="image-container">
                <img src={CheckEmail} alt='logo' />
              </div>
              <div className='regular-title'>
                Verifique seu e-mail
              </div>
              <div className='regular-text'>
                Enviamos um e-mail com um link de confirmação para <span>{email}</span>
              </div>
              <div className='regular-text'>
                Verifique sua caixa de entrada para concluir seu cadastro!
              </div>
              <div
                style={{ height: '204px' }}
              >

              </div>
              {(serviceError) ? (
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={(e) => setIsCreateUserSuccess(false)}
                  >
                    Alterar os dados e tentar novamente
                  </Button>
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={(e) => handleGoToLogin(e)}
                  >
                    Ok, entendi
                  </Button>
                </ButtonContainer>
              )}
            </>
          )}
        </div>
      </>
    )
  );
}
