import styled, { css } from "styled-components";

export const CardsContainer = styled.div`
  margin-bottom: 72px;
`;

export const Card = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  padding-top: 13px;
  border-radius: 4px;
  /* display: grid;
  align-items: flex-start;
  justify-content: space-between; */

  & + &{
    margin-top: 16px;
  }
  
  button{
    background: transparent;
    border: none;
    /* margin-left: 8px; */
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .info {
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    .share{

    }

    .text{
      width: 100%;
      padding-bottom: 12px;

      .appointment-name {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        width: 245px;
        
        strong{
          inline-size: 245px; 
          overflow-wrap: break-word;
          height: auto;
        }

        small {
          /* background: ${({ theme }) => theme.colors.primary.lighter};
          color: ${({ theme }) => theme.colors.primary.main}; */
          /* text-transform: uppercase; */
          padding: 4px;
          border-radius: 4px;
          margin-left: 8px;
        }
      }

      .span{
        color: var(--telembro-preto-l, #636668);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        min-height: 17px;
        margin-bottom: 6px;

        &.type{
          color: var(--telembro-azul-d, #A1A1A1);
          height: 26px;
        }

        img{
          margin-right: 5px;
        }
      }
    }
  }

  .actions{ 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E8E8E8;;
    padding: 13px;
    /* padding-bottom: 13px; */

    a, button {
      margin-left: 20px;

      img{
        width: 32px;
      }
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 24px;

  header{
    margin-bottom: 8px;

    .sort-button{
      background: transparent;
      border: none;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.main};

        img{
          height: 8px;
        }
      }
    }
  }

  justify-content: left;
  display: flex;
  flex-direction: column;

  img{
    height: 74px;
  }
`;

export const Container = styled.div`
  margin-top: 32px;
  position: relative;

  .fab-container{
    /* display:flex;
    justify-content:flex-end; */
    position:fixed;
    bottom:30px;
    left: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    /* max-width: 400px; */
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    /* background: black; */
    /* padding-right: 24px; */
    text-align: center;

    .iconbutton{
      text-align: center;
      /* width:50px;
      height:50px;
      border-radius: 100%;
      background: ${({ theme }) => theme.colors.primary.main};
      box-shadow: 10px 10px 5px #aaaaaa;
      cursor:pointer; */
      
      .options{
        padding-left: 0 !important;
        text-align: center;
        list-style-type: none;
        position:absolute;
        bottom:70px;
        width: 100%;
        left: 0;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-around;

        li{
          display:flex;
          justify-content:center;
          padding:5px;
          
          a{
            text-decoration: none;
            color:#FFF;
            padding:12px 15px;

            .btn-label{
              padding:12px 15px;
              margin-right:10px;
              width: 100%;
              white-space: nowrap;
              align-self: center;
              user-select:none;
              /* background-color: #000000;
              color:#FFF;
              border-radius: 3px;
              */
              box-shadow: 5px 5px 5px #aaaaaa; 
              background-color: #FFF;
              color: #A1A1A1;
              border-radius: 3px;
              border: 1px solid #A1A1A1;
            }
          }
        }
      }
      
      /* .icon{
        display:flex;
        align-items:center;
        justify-content:center;
        height: 100%;
        color:#FFF;
        font-size: 32px;

        img{
          height: 24px;
          
          &.close{
            transform: rotate(45deg);
          }
        }
      } */

      .button{
        margin: auto;
        button{
          background: var(--telembro-laranja, #F25C05);
          color: #FFF;
          box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
          height: 52px;
          border: 2px solid #FFF;
          border-radius: 10px !important;
          outline: none;
          padding: 0 16px;
          font-size: 16px;
          transition: background all 0.2s ease-in;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  strong{
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  padding-bottom: 16px;

  strong{
    color: #222;
    font-size: 16px;
  }

  a{
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    transition: all 0.2s ease-in;

    &:hover{
      background: ${({ theme }) => theme.colors.primary.light};
      border: 2px solid ${({ theme }) => theme.colors.primary.light};
      color: #FFF;
    }

  }
`;

export const Form = styled.form`
  div{
    /* margin-bottom: 8px; */
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray[900]};

    span{
     margin-right: 16px;
    }

    label{
     margin-right: 16px;
     cursor: pointer;
    }

    #react-select-3-placeholder{
      color: #CECECE !important;
    }

    #document-value{
      width: 100%;
      background: #FFF;
      box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      height: 52px;
      border: 2px solid #FFF;
      border-radius: 4px;
      outline: none;
      padding: 0 16px;
      font-size: 16px;
      transition: border-color 0.2s ease-in;
      appearance: none;

      &:focus{
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
      }

      ${({ theme, error }) => error && css`
      color: ${theme.colors.danger.main};
      border-color: ${theme.colors.danger.main} !important;

      &:focus{
        color: ${theme.colors.danger.light};
        border-color: ${theme.colors.danger.main} !important;
      }
    `};
    }
  }
`;