import React, {
  useState
  , useEffect
  , useCallback
} from 'react';
import Cropper from 'react-easy-crop';
// import html2canvas from 'html2canvas';
import UsersService from '../../services/UsersService';
import {
  // useParams,
  useHistory
} from 'react-router-dom';
import OrangeUpload from '../../assets/icons/orange-upload.svg';
import FileIcon from '../../assets/icons/file-icon.svg';
import Loader from '../../components/Loader';
import Config from '../../config/environments/local';
import GoBackHeader from "../../components/GoBackHeader";
import Button from '../Button';
import { localGet } from "../../lib/session";

const ImageCropper = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  // const [user, setUser] = useState({});
  // const [pictureFile, setPictureFile] = useState({});
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(false);
  // const { userId } = useParams();
  const [userId, setUserId] = useState(null);
  const { history } = useHistory();

  useEffect(() => {
    if (!userId) {
      if (sessionObject && sessionObject.userId) {
        setUserId(sessionObject.userId);
      }
    }
  }, [sessionObject, setUserId, userId]);

  const updateUserProfilePicture = useCallback(async (blob) => {
    try {
      setIsLoading(true);
      const formDataToSend = new FormData();

      formDataToSend.append('Id', (userId));
      formDataToSend.append('file', blob, 'cropped-image.jpg');

      const response = await new UsersService(Config.API_BASE_URL).UpdateProfilePicture(formDataToSend);

      if (response) {
        window.open('/profile', '_self');
      }
      // setUser(response.Result);
    } catch (error) {
      console.log('error ', error);
      history.push('/profile');
    } finally {
      setIsLoading(false);
    }
  }, [history, userId]);

  // Triggered when the user selects an image
  const onImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setImageFile(file);
    reader.addEventListener('load', () => {
      setImageSrc(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Function to create cropped image
  const getCroppedImage = useCallback(async () => {
    if (!imageSrc) return;

    const image = new Image();
    image.src = imageSrc;

    // Wait for the image to load
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    image.onload = () => {
      context.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      // Convert canvas to blob
      canvas.toBlob(async (blob) => {
        // Upload the cropped image to the backend
        await updateUserProfilePicture(blob);
      }, 'image/jpeg');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels]);

  return (
    <>
      <GoBackHeader
        backAction={"/profile"}
        title={"Foto de perfil"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <Loader isLoading={isLoading} />

        {/* <input type="file" accept="image/*" onChange={onImageChange} /> */}

        <div
          className="file-input-container"
        >
          <input
            id='appointment-file'
            className="inputFile custom-file-input"
            type="file"
            accept="image/*"
            name="appointmentFiles"
            onChange={onImageChange}
            style={{ display: "none" }}
          />

          <label htmlFor="appointment-file" className={`custom-file-label ${(imageFile && imageFile.name) ? 'selected' : ''}`}>
            {(imageFile && imageFile.name) ? (
              <>
                <img src={FileIcon} alt='file' />
                {imageFile.name}
              </>
            ) : (
              <>
                Escolher arquivo
                <img src={OrangeUpload} alt='upload' />
              </>
            )}
          </label>
        </div>

        <br />
        <br />
        {imageSrc && (
          <div style={{ position: 'relative', width: '100%', marginBottom: '16px', height: '500px' }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round" // Set crop shape to circle
              showGrid={false}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        )}
        <Button style={{ margin: 'auto', position: 'relative', zIndex: '5' }} onClick={getCroppedImage}>Confirmar</Button>
      </div>
    </>

  );
};

export default ImageCropper;
