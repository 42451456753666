import styled, { css } from "styled-components";

export default styled.input`  
  width: 100%;
  background: #FFF;
  min-height: 56px;
  border: none;
  height: 56px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  outline: none;
  padding: 25px 16px 9px 16px;
  appearance: none;
  color: #636668 !important;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.36px;

  &.small{
    /* max-width: 140px; */
    /* margin-left: 10px;
    margin-right: 10px; */

    /* &:last-of-type{
      margin-left: 10px;
    } */
  }

  &:focus{
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  ${({ theme, error }) => error && css`
    color: ${theme.colors.danger.main};
    border-color: ${theme.colors.danger.main} !important;

    &:focus{
      color: ${theme.colors.danger.light};
      border-color: ${theme.colors.danger.main} !important;
    }

  `};

  &.document-value{
    width: 100%;
    background: #FFF;
    min-height: 56px;
    border: none;
    height: 56px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    outline: none;
    padding: 25px 16px 9px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #636668 !important;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    -webkit-letter-spacing: -0.36px;
    -moz-letter-spacing: -0.36px;
    -ms-letter-spacing: -0.36px;
    letter-spacing: -0.36px;

    &:focus{
      border: 1px solid #F25C05;
    }
  }

  &.react-date-picker__inputGroup__input{
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    height: 52px;
    border: 2px solid #FFF;
    border-radius: 4px;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    transition: border-color 0.2s ease-in;
    appearance: none;

    &:focus{
      border: 2px solid ${({ theme }) => theme.colors.primary.main};
    }

    ${({ theme, error }) => error && css`
      color: ${theme.colors.danger.main};
      border-color: ${theme.colors.danger.main} !important;

      &:focus{
        color: ${theme.colors.danger.light};
        border-color: ${theme.colors.danger.main} !important;
      }

    `};
  }
`;
