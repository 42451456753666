import styled, { css } from "styled-components";

export const Form = styled.form`
  div{
    /* margin-bottom: 8px; */
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray[900]};

    span{
     margin-right: 16px;
    }

    label{
     margin-right: 16px;
     cursor: pointer;
    }

    #react-select-3-placeholder{
      color: #CECECE !important;
    }

    #document-value{
      width: 100%;
      background: #FFF;
      box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      height: 52px;
      border: 2px solid #FFF;
      border-radius: 4px;
      outline: none;
      padding: 0 16px;
      font-size: 16px;
      transition: border-color 0.2s ease-in;
      appearance: none;

      &:focus{
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
      }

      ${({ theme, error }) => error && css`
      color: ${theme.colors.danger.main};
      border-color: ${theme.colors.danger.main} !important;

      &:focus{
        color: ${theme.colors.danger.light};
        border-color: ${theme.colors.danger.main} !important;
      }
    `};
    }
  }
`;

export const CheckboxAllContainer = styled.div`
  margin: auto;
  margin-right: 8px;
  margin-bottom: 24px;

  label{
    margin: auto;

    input{
      cursor: pointer;
      vertical-align: middle;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
`;

export const CardsContainer = styled.div`
  /* margin-bottom: 72px; */
  z-index: 99;
`;

export const MessageCardsContainer = styled.div`
    
`;

export const ShareContainer = styled.div`
  /* margin-bottom: 72px; */
  .info {
    width: 100%;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
      width: 245px;
      
      strong{
        inline-size: 245px; 
        overflow-wrap: break-word;
        height: auto;
      }

      small {
        background: ${({ theme }) => theme.colors.primary.lighter};
        color: ${({ theme }) => theme.colors.primary.main};
        text-transform: uppercase;
        padding: 4px;
        border-radius: 4px;
        margin-left: 8px;
      }
    }

    .span{
      display: block;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.gray[200]};
      margin-bottom: 12px;
    }
  }

  .actions{ 
    padding-top: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button{
      /* width: 100%; */
    }

    a, button {
      margin-left: 20px;

      img{
        width: 32px;
      }
    }
  }
`;

export const CardAndCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CheckboxContainer = styled.div`
  margin: auto;

  label{
    margin: auto;

    input{
      cursor: pointer;
      vertical-align: middle;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
`;

export const Card = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  padding-top: 13px;
  border-radius: 4px;
  position: fixed;
  top: 50px;
  width: 100%;
  left: 0;

  & + &{
    margin-top: 16px;
  }
  
  button{
    background: transparent;
    border: none;
    /* margin-left: 8px; */
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .info {
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    .share{

    }

    .text{
      width: 100%;
      padding-bottom: 12px;

      .appointment-name {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        width: 245px;
        
        strong{
          inline-size: 245px; 
          overflow-wrap: break-word;
          height: auto;
        }

        small {
          /* background: ${({ theme }) => theme.colors.primary.lighter};
          color: ${({ theme }) => theme.colors.primary.main}; */
          /* text-transform: uppercase; */
          padding: 4px;
          border-radius: 4px;
          margin-left: 8px;
        }
      }

      .span{
        color: var(--telembro-preto-l, #636668);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        min-height: 17px;
        margin-bottom: 6px;

        &.type{
          color: var(--telembro-azul-d, #A1A1A1);
          height: 26px;
        }

        img{
          margin-right: 5px;
        }
      }

      .badges{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;

        .appointment-type-badge{
          margin-right: 14px;
          border-radius: 30px;
          background: var(--apoio-azul, #BBE8F2);
          padding: 7px 10px;
          color: var(--dark, #011F26);
          text-align: center;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          &.habilitacao-identidade-passaporte{
            background: var(--apoio-azul, #BBBDF2);
          }

          &.boleto-fatura-carne{
            background: var(--apoio-azul, #F2C5BB);
          }

          &.reuniao{
            background: var(--apoio-azul, #BBE8F2);
          }

          &.medico{
            background: var(--apoio-azul, #F2E6BB);
          }

          &.criada-usuario{
            background: var(--apoio-azul, #EBEBEB);
          }

          &.recorrente{
            background: #D7FBCE;

            img{
              margin: auto;
              margin-top: -2px;
              margin-right: 4px;
            }
          }
        }
      }

      .appointment-date-time{
        color: #BEBEBE;
        text-align: left;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 6px;

        img{
          margin: auto;
          margin-top: -2px;
          margin-right: 6px;
        }
      }

      .appointment-name{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
        width: 100%;
      }

      .appointment-observation{
        color: var(--dark, #011F26);
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .actions{ 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E8E8E8;;
    padding: 13px;
    /* padding-bottom: 13px; */

    a, button {
      text-decoration: none;
      font-size: 32px;
      margin-left: 20px;

      img{
        width: 32px;
      }
    }
  }

  .actions{ 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    button{
      background: transparent;
      border: none;
      margin-right: 8px;
      margin-left: 8px;
      cursor: pointer;
    }

    a{
      text-decoration: none;
      font-size: 28px;

      &.confirm{
        color: green;
      }

      &.refuse{
        color: red;
      }
    }

    a, button {
      margin-left: 20px;

      img{
        width: 32px;
      }
    }
  }
`;

export const MessageCard = styled.div`
  
`;

export const ListContainer = styled.div`
  margin-top: 24px;

  header{
    margin-bottom: 8px;

    .sort-button{
      background: transparent;
      border: none;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.main};

        img{
          height: 8px;
        }
      }
    }
  }

  justify-content: left;
  display: flex;
  flex-direction: column;

  img{
    height: 74px;
  }
`;

export const Container = styled.div`
  /* margin-top: 32px; */
  position: relative;
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  strong{
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  padding-bottom: 16px;

  strong{
    color: #222;
    font-size: 16px;
  }

  a{
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    transition: all 0.2s ease-in;

    &:hover{
      background: ${({ theme }) => theme.colors.primary.light};
      border: 2px solid ${({ theme }) => theme.colors.primary.light};
      color: #FFF;
    }
  }
`;