/* eslint-disable jsx-a11y/anchor-is-valid */
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import Button from '../Button';
// import Button from '../Button';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import PropTypes from 'prop-types';
// import BasicHeader from '../BasicHeader';
import BottomMenu from '../BottomMenu';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização para português do Brasil

moment.locale('pt-br'); // Define a localização para português do Brasil

export default function CalendarVision({ selectedDate, setSelectedDate, setMonthSelectionMode }) {
  const [calendarAppointments, setCalendarAppointments] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [activeDay, setActiveDay] = useState({});

  const getCalendarVision = useCallback(async (dateSelected) => {
    if (!sessionObject || !dateSelected) {
      return;
    }

    try {
      setIsLoading(true);
      const getCalendarVisionResponse = await new AppointmentsService(Config.API_BASE_URL).GetCalendarVision(
        {
          UserId: sessionObject.userId,
          SelectedDate: dateSelected,
          // SelectedDate: '2023-12-10',
        }
      );
      setCalendarAppointments(getCalendarVisionResponse.Result);
      // const activeWeek = getCalendarVisionResponse.Result.Weeks.filter(f => f.IsActiveWeek === true);
      // const activeDay = activeWeek[0].WeekDays.filter(f => f.IsActiveDay === true);
      // setActiveDay(activeDay[0]);
      // setCurrentYear(getCalendarVisionResponse.Result.SelectedDateYear);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, setCalendarAppointments]);

  // async function handleReload() {
  //   await getCalendarVision();
  // };

  // const handleReload = useCallback(async () => {
  //   await getCalendarVision(setSelectedDate);
  // }, [getCalendarVision, setSelectedDate]);

  async function handleChangeSelectedDate(dateSelected) {
    console.log(dateSelected);
    setMonthSelectionMode(false);
    setSelectedDate(dateSelected);
    await getCalendarVision(dateSelected);
  };

  useEffect(() => {
    getCalendarVision(selectedDate);
  }, [getCalendarVision, selectedDate]);

  // function getWeekDayWidth(weekDays, isLastOne) {
  //   switch (weekDays) {
  //     case 2:
  //       if (isLastOne) {
  //         return 'quarentaeoito';
  //       }
  //       else {
  //         return 'quarentaeoito';
  //       }
  //     case 3:
  //       if (isLastOne) {
  //         return 'trinta';
  //       }
  //       else {
  //         return 'trinta';
  //       }
  //     case 4:
  //       if (isLastOne) {
  //         return 'quarentaeoito';
  //       }
  //       else {
  //         return 'quarentaeoito';
  //       }
  //     case 5:
  //       if (isLastOne) {
  //         return 'cem';
  //       }
  //       else {
  //         return 'quarentaeoito';
  //       }
  //     case 6:
  //       if (isLastOne) {
  //         return 'trinta';
  //       }
  //       else {
  //         return 'trinta';
  //       }
  //     case 7:
  //       if (isLastOne) {
  //         return 'trinta';
  //       }
  //       else {
  //         return 'trinta';
  //       }
  //     default:
  //       return 'cem';
  //   }
  // }

  // function getFullMonthName(date) {

  //   // date = isNaN(date) ? new Date() : new Date(`${date} `);

  //   const formatter = new Intl.DateTimeFormat('pt-BR', { caseFirst: "upper", month: 'long' });
  //   const monthName = formatter.format(new Date(date));

  //   switch (monthName) {
  //     case 'janeiro':
  //       return 'Janeiro';
  //     case 'fevereiro':
  //       return 'Fevereiro';
  //     case 'março':
  //       return 'Março';
  //     case 'abril':
  //       return 'Abril';
  //     case 'maio':
  //       return 'Maio';
  //     case 'junho':
  //       return 'Junho';
  //     case 'julho':
  //       return 'Julho';
  //     case 'agosto':
  //       return 'Agosto';
  //     case 'setembro':
  //       return 'Setembro';
  //     case 'outubro':
  //       return 'Outubro';
  //     case 'novembro':
  //       return 'Novembro';
  //     case 'dezembro':
  //       return 'Dezembro';
  //     default:
  //       return '-';
  //   }
  // }

  return (
    <div className='basic-container'>
      <Loader isLoading={isLoading} />
      {/* <Loader isLoading={false} /> */}

      {/* <BasicHeader /> */}
      <BottomMenu />

      {/* {(calendarAppointments && calendarAppointments.Weeks) && (
        <div className='navigation-buttons-container'>
          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation'
              key='1'
              onClick={(e) => {
                handleChangeSelectedDate(calendarAppointments.PreviousMonthDate)
              }}
            >
              {calendarAppointments.PreviousMonthDate ? `< ${calendarAppointments.PreviousMonthName}` : ''}

            </Button>
          </div>


          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation current-month-name'
              key='3'
            >
              {calendarAppointments.SelectedDate ? `${calendarAppointments.SelectedDateMonthName}` : ''}
            </Button>
          </div>

          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation'
              key='2'
              onClick={(e) => {
                handleChangeSelectedDate(calendarAppointments.NextMonthDate)
              }}
            >
              {calendarAppointments.NextMonthDate ? `${calendarAppointments.NextMonthName} >` : ''}
            </Button>
          </div>
        </div>
      )} */}

      <div className='week-names-row-container'>
        <div className='week-names-row'>
          <div className='week-name'>
            DOM
          </div>
          <div className='week-name'>
            SEG
          </div>
          <div className='week-name'>
            TER
          </div>
          <div className='week-name'>
            QUA
          </div>
          <div className='week-name'>
            QUI
          </div>
          <div className='week-name'>
            SEX
          </div>
          <div className='week-name'>
            SÁB
          </div>
        </div>
      </div>

      <div className='week-rows-container'>
        {(calendarAppointments && calendarAppointments.Weeks) && (
          calendarAppointments.Weeks.map((week, i) => (
            <div
              key={`weeks-${i}`}
              className='week-row-container'
            >
              <div className='week-days-container'
                key={`week-${i}`}
              >
                {week.WeekDays.map((weekDay) => (
                  <>
                    {!weekDay.IsNullDay && (
                      <div
                        key={`activeweekday-${weekDay.Day}`}
                        className={`week-day ${weekDay.HasAppointments && ('has-appointments')} ${weekDay.IsToday && ('today')} ${(weekDay.DayDate === selectedDate) && ('selected-date')}`}
                        onClick={(e) => {
                          handleChangeSelectedDate(weekDay.DayDate)
                        }}
                      >
                        {weekDay.Day}
                      </div>
                    )}

                    {weekDay.IsNullDay && (
                      <div
                        key={`day-${weekDay.Day}`}
                        className={`null-day`}
                      >
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div >
  );
}

CalendarVision.propTypes = {
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func.isRequired
}