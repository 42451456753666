import { useParams, useHistory } from 'react-router-dom';
// import PageHeader from "../../components/PageHeader";
import AppointmentForm from "../../components/AppointmentForm";
import AppointmentsService from '../../services/AppointmentsService';
import { useState, useEffect } from "react";
import Loader from '../../components/Loader';
import { format } from 'date-fns';
import Config from '../../config/environments/local';
import { localGet } from "../../lib/session";

export default function EditAppointment() {

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [appointment, setAppointment] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { history } = useHistory();

  useEffect(() => {
    async function getAppointment() {
      try {
        setIsLoading(true);

        const response = await new AppointmentsService(Config.API_BASE_URL).getAppointment(id);

        response.Result.AppointmentDate = format(new Date(response.Result.AppointmentDate), 'dd/MM/yyyy');

        if (response.Result.AppointmentRecurrenceEndDate) {
          response.Result.AppointmentRecurrenceEndDate = format(new Date(response.Result.AppointmentRecurrenceEndDate), 'dd/MM/yyyy');
        }

        if (response.Result.ReminderDates) {
          for (let i = 0; i < response.Result.ReminderDates.length; i++) {
            const formattedDate = format(new Date(response.Result.ReminderDates[i]), 'dd/MM/yyyy');
            response.Result.ReminderDates[i] = formattedDate;
          }
        }

        setAppointment(response.Result);
      } catch (error) {
        console.log('error ', error);
        history.push('/');
      } finally {
        setIsLoading(false);
      }
    }

    getAppointment();
  }, [id, history]);

  async function handleSubmit(formData) {
    const [day, month, year] = formData.appointmentDate.split('/');
    const appointmentDate = new Date(+year, month - 1, +day);

    let reminderDate1Date = '';
    let reminderDate2Date = '';
    let reminderDate3Date = '';
    let recurrenceDateDate = '';

    if (formData.reminderDate1) {
      const [day1, month1, year1] = formData.reminderDate1.split('/');
      const reminderDate1 = new Date(+year1, month1 - 1, +day1);
      reminderDate1Date = format(new Date(reminderDate1), 'yyyy-MM-dd');
    }

    if (formData.reminderDate2) {
      const [day2, month2, year2] = formData.reminderDate2.split('/');
      const reminderDate2 = new Date(+year2, month2 - 1, +day2);
      reminderDate2Date = format(new Date(reminderDate2), 'yyyy-MM-dd');
    }

    if (formData.reminderDate3) {
      const [day3, month3, year3] = formData.reminderDate3.split('/');
      const reminderDate3 = new Date(+year3, month3 - 1, +day3);
      reminderDate3Date = format(new Date(reminderDate3), 'yyyy-MM-dd');
    }

    if (formData.appointmentRecurrenceEndDate) {
      const [dayRecurrence, monthRecurrence, yearRecurrence] = formData.appointmentRecurrenceEndDate.split('/');
      const recurrenceDate = new Date(+yearRecurrence, monthRecurrence - 1, +dayRecurrence);
      recurrenceDateDate = format(new Date(recurrenceDate), 'yyyy-MM-dd');
    }

    const formDataToSend = new FormData();

    formDataToSend.append('Id', appointment.Id);
    formDataToSend.append('Name', (formData.name ?? ''));
    formDataToSend.append('Observations', (formData.observations ?? ''));
    formDataToSend.append('AppointmentCategoryId', formData.appointmentCategoryId);
    formDataToSend.append('AppointmentTypeId', formData.appointmentTypeId);
    formDataToSend.append('BarCodeNumber', (formData.barCodeNumber ?? ''));
    formDataToSend.append('DocumentValue', (formData.realDocumentValue ?? ''));
    formDataToSend.append('AppointmentTime', (formData.appointmentTime ?? ''));
    formDataToSend.append('AppointmentFiles', formData.appointmentFiles);
    formDataToSend.append('AppointmentFiles2', formData.appointmentFiles2);
    formDataToSend.append('AppointmentFiles3', formData.appointmentFiles3);
    formDataToSend.append('AppointmentFiles4', formData.appointmentFiles4);
    formDataToSend.append('AppointmentFiles5', formData.appointmentFiles5);
    formDataToSend.append('ReminderDate1', reminderDate1Date);
    formDataToSend.append('ReminderDate2', reminderDate2Date);
    formDataToSend.append('ReminderDate3', reminderDate3Date);
    formDataToSend.append('AppointmentDate', format(new Date(appointmentDate), 'yyyy-MM-dd'));
    formDataToSend.append('UserId', sessionObject.userId);

    formDataToSend.append('AppointmentRecurrenceId', (formData.appointmentRecurrenceId ?? ''));
    formDataToSend.append('AppointmentRecurrenceTypeId', (formData.appointmentRecurrenceTypeId ?? ''));
    formDataToSend.append('AppointmentRecurrenceEndTime', (formData.appointmentRecurrenceEndTime ?? ''));
    formDataToSend.append('AppointmentRecurrenceUnity', (formData.appointmentRecurrenceUnity ?? ''));
    formDataToSend.append('AppointmentRecurrenceEndDate', recurrenceDateDate);
    formDataToSend.append('HourAdvanceReminder', (formData.hourAdvanceReminder ?? ''));

    formDataToSend.append('AppointmentAdvanceReminderUnit1', (formData.appointmentAdvanceReminderUnit1 ?? ''));
    formDataToSend.append('appointmentAdvanceReminderType1', (formData.appointmentAdvanceReminderType1 ?? ''));
    formDataToSend.append('AppointmentAdvanceReminderUnit2', (formData.appointmentAdvanceReminderUnit2 ?? ''));
    formDataToSend.append('appointmentAdvanceReminderType2', (formData.appointmentAdvanceReminderType2 ?? ''));
    formDataToSend.append('AppointmentAdvanceReminderUnit3', (formData.appointmentAdvanceReminderUnit3 ?? ''));
    formDataToSend.append('appointmentAdvanceReminderType3', (formData.appointmentAdvanceReminderType3 ?? ''));
    formDataToSend.append('AppointmentAdvanceReminderUnit4', (formData.appointmentAdvanceReminderUnit4 ?? ''));
    formDataToSend.append('appointmentAdvanceReminderType4', (formData.appointmentAdvanceReminderType4 ?? ''));
    formDataToSend.append('AppointmentAdvanceReminderUnit5', (formData.appointmentAdvanceReminderUnit5 ?? ''));
    formDataToSend.append('appointmentAdvanceReminderType5', (formData.appointmentAdvanceReminderType5 ?? ''));
    formDataToSend.append('MustSendPushNotification', (formData.appointmentHasPushNotifications ?? ''));
    formDataToSend.append('SharedUserEmails', (formData.emails ?? ''));

    await new AppointmentsService(Config.API_BASE_URL).updateAppointmentFormData(formDataToSend);

    window.open('/', '_self');

    // const appointmentId = appointment.Id;
    // if ((formData.appointmentFiles ||
    //   formData.appointmentFiles2 ||
    //   formData.appointmentFiles3 ||
    //   formData.appointmentFiles4 ||
    //   formData.appointmentFiles5)) {

    //   const formAttachmentDataToSend = new FormData();
    //   if (formData.appointmentFiles) {
    //     formAttachmentDataToSend.append('AppointmentFiles', formData.appointmentFiles);
    //   }

    //   if (formData.appointmentFiles2) {
    //     formAttachmentDataToSend.append('AppointmentFiles2', formData.appointmentFiles2);
    //   }

    //   if (formData.appointmentFiles3) {
    //     formAttachmentDataToSend.append('AppointmentFiles3', formData.appointmentFiles3);
    //   }

    //   if (formData.appointmentFiles4) {
    //     formAttachmentDataToSend.append('AppointmentFiles4', formData.appointmentFiles4);
    //   }

    //   if (formData.appointmentFiles5) {
    //     formAttachmentDataToSend.append('AppointmentFiles5', formData.appointmentFiles5);
    //   }
    //   formAttachmentDataToSend.append('Id', appointmentId);
    //   const updateAppointmentAtachmentResponse = await new AppointmentsService(Config.FILE_UPLOAD_API_BASE_URL).updateAppointmentAtachmentFormData(formAttachmentDataToSend);

    //   if (updateAppointmentAtachmentResponse.Result) {
    //     window.open('/', '_self');
    //   }
    //   else {
    //     alert('Não foi possível adicionar seu(s) anexo(s). Tente novamente.');
    //     window.open(`/edit/${appointmentId}`, '_self');
    //   }
    //   // console.log(updateAppointmentAtachmentResponse);
    // }
    // else {
    //   window.open('/', '_self');
    // }
  }

  if (!sessionObject) {
    return;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {/* <PageHeader
        title='Editar compromisso'
      /> */}
      <AppointmentForm
        key={appointment.Id}
        buttonLabel='Salvar compromisso'
        onSubmit={handleSubmit}
        // onRemoveAttachment={handleRemoveAttachment}
        appointment={appointment}
        // appointmentCategoryId={appointment.AppointmentCategoryId}
        appointmentCategoryId={appointment.AppointmentCategoryId}
        viewerUserId={sessionObject.userId}
      />
    </>
  )
};