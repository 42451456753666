import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  // Container,
  // Header
} from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
// import PageHeader from '../../components/PageHeader';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import BigLogo from '../../assets/icons/menu/big-logo.svg'
import RecuperarSenha from '../../assets/images/img_recuperar_senha.png'
import GoBackHeader from "../../components/GoBackHeader";
import NoPendingItems from '../../assets/images/img_success.png';
// import CheckEmail from '../../assets/images/check-email.png';

export default function ResetPassword() {

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const isFormValid = (password && passwordAgain && (password === passwordAgain));
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');
  const [userId, setUserId] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const { code } = useParams();
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const getUserByCode = useCallback(async () => {
    if (!hasRequested) {
      try {
        setIsLoading(true);
        setHasRequested(true);
        const response = await new UsersService(Config.API_BASE_URL).GetUserByCode({
          code
        });

        if (response.Error) {
          setError({ field: 'password', message: response.Error.Message });
          setServiceError(response.Error.Message);
        }
        else {
          setUserId(response.Result);
        }

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [code, setError, hasRequested]);

  // async function handleReload() {
  //   await getUserByCode();
  // };

  useEffect(() => {
    getUserByCode();
  }, [getUserByCode]);

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    } else {
      if (passwordAgain && (passwordAgain !== e.target.value)) {
        setError({ field: 'password', message: 'digite a mesma senha' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('passwordAgain');
      }
    }
  }

  function handleChangePasswordAgain(e) {
    setPasswordAgain(e.target.value);

    if (!e.target.value) {
      setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'passwordAgain', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('passwordAgain');
      }
    }
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).UpdatePassword({
      UserId: userId,
      Password: password,
    });

    if (response.Error) {
      setError({ field: 'password', message: response.Error.Message });
      setServiceError(response.Error.Message);
      setIsResetPaswordSuccess(true);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('password');
      removeError('passwordAgain');
    }

    setIsLoading(false);

  }

  return (
    <>
      {(!isResetPaswordSuccess) && (
        <GoBackHeader
          backAction={"/login"}
          title={"Criar nova senha"}
        />
      )}

      <div className='basic-container'>
        {(!isResetPaswordSuccess) ? (
          <>
            <div className='image-container'>
              <img src={RecuperarSenha} alt='logo' />
            </div>
            <div className='regular-title'>
              Crie uma nova senha
            </div>
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup
                error={getErrorMessageByFieldName('password')}
              >
                <div className='form-label'>Criar senha</div>
                <div className="input-group-btn">
                  <Input
                    type={passwordType}
                    value={password}
                    placeholder="Insira sua senha"
                    onChange={handleChangePassword}
                    error={getErrorMessageByFieldName('password')}
                  />
                  <button type="button" className="btn" onClick={togglePassword}>
                    {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </button>
                </div>
              </FormGroup>
              <div className='small-disclaimer'>
                Mínimo de 8 caracteres <br />
                Possuir letras e números <br />
                Pelo menos 1 legra maiúscula <br />
                Pelo menos 1 caractere especial (#@!)
              </div>
              <FormGroup
                error={getErrorMessageByFieldName('passwordAgain')}
              >
                <div className='form-label'>Confirmar senha</div>
                <div className="input-group-btn">
                  <Input
                    type={passwordType}
                    value={passwordAgain}
                    placeholder="Insira sua senha"
                    onChange={handleChangePasswordAgain}
                    error={getErrorMessageByFieldName('passwordAgain')}
                  />
                  <button type="button" className="btn" onClick={togglePassword}>
                    {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </button>
                </div>
              </FormGroup>

              <Loader isLoading={isLoading} />

              <ButtonContainer>
                <Button
                  type="button"
                  disabled={!isFormValid}
                  onClick={(e) => handleSubmit(e)}

                >
                  Confirmar
                </Button>
              </ButtonContainer>
            </Form >
          </>
        ) : (
          <>
            <div className="big-logo-container smaller">
              <img src={BigLogo} alt='logo' />
            </div>
            <div className='image-container'>
              <img src={NoPendingItems} alt='logo' />
              {/* <img src={NoPendingItems} alt='logo' /> */}
            </div>

            <div className='regular-title'>
              Sua nova senha foi criada com sucesso!
            </div>
            <div className='regular-text'>
              Faça seu login novamente para continuar.
            </div>

            <div style={{ height: '27px' }}></div>

            {(serviceError) ? (
              <ButtonContainer>
                <Button
                  type="button"
                  onClick={(e) => setIsResetPaswordSuccess(false)}
                >
                  Alterar o e-mail e tentar novamente
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Button
                  type="button"
                  onClick={(e) => handleGoToLogin(e)}
                >
                  Ok, entendi
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </div>

    </>
  )
};