import UsersService from '../../services/UsersService';
import Config from '../../config/environments/local';
import BigLogo from '../../assets/icons/menu/big-logo.svg'
import {
  useState,
  useEffect,
} from 'react';

export default function AllUsers() {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function getUsers(e) {

      const response = await new UsersService(Config.API_BASE_URL).GetAll();

      if (response.Error) {
        alert(response.Error.Message);
        console.log('Ocorreu um erro ao recuperar e-mails');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setUsers(response.Result);
      }
    }

    getUsers();
  }, []);

  return (
    <>
      {(users.length > 0) && (
        <>
          <div className='basic-container bottom-overflow'>
            <div className="big-logo-container">
              <img src={BigLogo} alt='logo' />
            </div>

            <div className="row">
              <div className="col-md-12">
                Total: {users.length}
                <br />
                <br />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                    </tr>
                  </thead>
                  {(users) && (
                    users.map(item => (
                      <tr>
                        <td>
                          {item.Name}
                        </td>
                        <td>
                          {item.Email}
                        </td>
                      </tr>
                    ))
                  )}
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
};